<div class="filter-multiselect" data-filter="model">
  <div class="multiselect-items">
    <div *ngFor="let option of options | arrayFilter: filterSelectedOptions.bind(this)"
         class="btn btn-icon btn-transparent-blue">
      <span>{{option.name}}</span>
      <svg (click)="toggleItemSelection(option)">
        <use xlink:href="#i-close-filter"></use>
      </svg>
    </div>
  </div>
  <app-catalog-filter-model-dropdown
    [options]="options"
    (change)="setOptions($event)"
  ></app-catalog-filter-model-dropdown>
</div>
