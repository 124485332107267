import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ICatalogFilterColorOption} from '../../interfaces/catalog-filter-color-option.interface';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter-colors',
  templateUrl: './catalog-filter-colors.component.html',
  styleUrls: ['./catalog-filter-colors.component.scss']
})
export class CatalogFilterColorsComponent implements OnInit {
  options: ICatalogFilterColorOption[] = [
    {
      color: 'black',
      value: 1,
      selected: false,
    },
    {
      color: 'silver',
      value: 2,
      selected: false,
    },
    {
      color: 'white',
      value: 3,
      selected: false,
    },
    {
      color: 'gray',
      value: 4,
      selected: false,
    },
    {
      color: 'blue',
      value: 5,
      selected: false,
    },
    {
      color: 'red',
      value: 6,
      selected: false,
    },
    {
      color: 'green',
      value: 7,
      selected: false,
    },
    {
      color: 'brown',
      value: 8,
      selected: false,
    },
    {
      color: 'beige',
      value: 9,
      selected: false,
    },
    {
      color: 'skyblue',
      value: 10,
      selected: false,
    },
    {
      color: 'gold',
      value: 11,
      selected: false,
    },
    {
      color: 'violet',
      value: 12,
      selected: false,
    },
    {
      color: 'yellow',
      value: 13,
      selected: false,
    },
    {
      color: 'orange',
      value: 14,
      selected: false,
    },
    {
      color: 'purple',
      value: 15,
      selected: false,
    },
    {
      color: 'pink',
      value: 16,
      selected: false,
    },
  ];

  @Output()
  change = new EventEmitter<ICatalogFilterColorOption[]>();

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    this.carFilterService.filterReset$.subscribe(() => this.reset());
  }

  public toggleOption(option: ICatalogFilterColorOption): void {
    option.selected = !option.selected;
    this.change.emit(this.options);
    this.updateFilter();
  }

  public trackOption(index: number, option: ICatalogFilterColorOption): number {
    return option.value;
  }

  public updateFilter(): void {
    const colorIds = this.options.filter(o => o.selected).map(o => o.value);
    this.carFilterService.setColors(colorIds);
  }

  private reset(): void {
    this.options.forEach(o => o.selected = false);
    this.carFilterService.setColors(null);
  }
}
