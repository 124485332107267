import {Injectable} from '@angular/core';
import {ModelsService} from '../../shared/services/models.service';
import {CarFilterService} from './car-filter.service';
import {IApiModel} from '../../api/interfaces/api-model.interface';
import {Logger} from '../../shared/helpers/logger';
import {IDropdownOption} from '../../shared/interfaces/dropdown-option.interface';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarFilterModelService {
  public options$ = new ReplaySubject<IDropdownOption<number>[]>();

  private readonly logger = new Logger(CarFilterModelService.name);

  private initialized = false;
  private options: IDropdownOption<number>[];

  constructor(
    private readonly modelsService: ModelsService,
    private readonly carFilterService: CarFilterService
  ) {
  }

  tryInit(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this.modelsService.loadModels();
    this.modelsService.models$.subscribe(models => this.setOptions(models));
    this.options$.subscribe(options => this.updateFilter(options));
  }

  setOptions(models: IApiModel[]): void {
    const selectedOptions: number[] = this.options?.filter(o => o.selected).map(o => o.value) || [];

    if (this.modelsService.getSelectedModel()) {
      selectedOptions.push(this.modelsService.getSelectedModel());
      this.modelsService.setHomeSelectedModel(null);
    }

    this.logger.log('setOptions start', selectedOptions, models.map(m => m.id), new Date());

    this.options = models.map(model => ({
      id: model.id,
      name: model.modelName,
      value: model.id,
      selected: selectedOptions.includes(model.id),
    }));

    this.logger.log('setOptions end', this.options.filter(o => o.selected).map(o => o.id), models.map(m => m.id));

    this.options$.next(this.options);
  }

  updateFilter(options: IDropdownOption<number>[]): void {
    const selectedOptions = options?.filter(o => o.selected).map(o => o.id);
    this.carFilterService.setModels(selectedOptions);
  }
}
