import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortingDirection} from '../../../shared/consts/sorting-direction.const';

@Component({
  selector: 'app-catalog-sort-button',
  templateUrl: './catalog-sort-button.component.html',
  styleUrls: ['./catalog-sort-button.component.scss']
})
export class CatalogSortButtonComponent implements OnInit {
  @Input()
  active = false;

  @Output()
  changeSorting = new EventEmitter<SortingDirection>();

  isAsc = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  handleClick(): void {
    if (this.active) {
      this.toggleDirection();
    }

    this.changeSorting.emit(this.getSortingDirection());
  }

  toggleDirection(): void {
    this.isAsc = !this.isAsc;
  }

  getSortingDirection(): SortingDirection {
    return this.isAsc ? SortingDirection.asc : SortingDirection.desc;
  }
}
