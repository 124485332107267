import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeographySection} from '../../const/geography-section.const';

@Component({
  selector: 'app-geography-toggle',
  templateUrl: './geography-toggle.component.html',
  styleUrls: ['./geography-toggle.component.scss']
})
export class GeographyToggleComponent implements OnInit {
  @Input()
  currentSection: GeographySection = 'map';

  @Output()
  changeSection = new EventEmitter<GeographySection>();

  constructor() {
  }

  ngOnInit(): void {
  }

  openSection(section: GeographySection): void {
    this.currentSection = section;
    this.changeSection.emit(this.currentSection);
  }
}
