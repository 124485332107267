import {Injectable} from '@angular/core';
import {IApiCitiesResponse} from '../../api/interfaces/api-cities.response.interface';
import {ApiCitiesService} from '../../api/services/api-cities.service';
import {DefaultCityId} from '../consts/default-city-id.const';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  public cities$ = new ReplaySubject<IApiCitiesResponse>();

  private cities: IApiCitiesResponse = null;
  private selectedCityId: number = DefaultCityId;

  constructor(
    private readonly apiCitiesService: ApiCitiesService
  ) {
  }

  loadCities(): void {
    if (!this.cities) {
      const s = this.apiCitiesService.getCities().subscribe(response => {
        this.cities = response;
        this.cities$.next(this.cities);
      });
    }
  }

  getCities(): IApiCitiesResponse {
    return this.cities;
  }

  setSelectedCity(cityId: number): void {
    this.selectedCityId = cityId;
  }

  getSelectedCityId(): number {
    return this.selectedCityId;
  }
}
