<div class="catalog-list">
  <div
    class="row"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="handleScroll()"
  >
    <div
      class="col-sm-12 col-lg-6 col-xl-4"
      *ngFor="let card of cards$ | async; trackBy: trackCard"
    >
      <app-catalog-list-card
        [carCard]="card"
        [carDetailComponent]="carDetailComponent"
      ></app-catalog-list-card>
    </div>
  </div>
</div>
<app-catalog-car-detail #carDetailComponent></app-catalog-car-detail>
