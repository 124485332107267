import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IApiDealersCityDealer} from '../../../api/interfaces/api-dealer-city.interface';
import {DealersService} from '../../services/dealers.service';

@Component({
  selector: 'app-geography-list',
  templateUrl: './geography-list.component.html',
  styleUrls: ['./geography-list.component.scss']
})
export class GeographyListComponent implements OnInit {
  dealers: IApiDealersCityDealer[];

  constructor(
    private readonly dealersService: DealersService
  ) {
    this.handleDealerClick = this.handleDealerClick.bind(this);
  }

  ngOnInit(): void {
    this.dealersService.currentCity$.subscribe(currentCity => {
      this.dealers = currentCity.dealers;
    });
  }

  handleDealerClick(dealer: IApiDealersCityDealer): void {
    this.dealersService.gotoDealer$.emit(dealer);
  }
}
