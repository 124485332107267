import {NgModule} from '@angular/core';
import {CatalogPageComponent} from './pages/catalog-page/catalog-page.component';
import {RouterModule} from '@angular/router';
import {HeaderAppDownloadBannerComponent} from './components/header-app-download-banner/header-app-download-banner.component';
import {CatalogListComponent} from './components/catalog-list/catalog-list.component';
import {CatalogListCardComponent} from './components/catalog-list-card/catalog-list-card.component';
import {SharedModule} from '../shared/shared.module';
import {ApiModule} from '../api/api.module';
import {CatalogFilterComponent} from './components/catalog-filter/catalog-filter.component';
import { CatalogFilterModelComponent } from './components/catalog-filter-model/catalog-filter-model.component';
import { CatalogFilterModelDropdownComponent } from './components/catalog-filter-model-dropdown/catalog-filter-model-dropdown.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CatalogFilterPriceComponent } from './components/catalog-filter-price/catalog-filter-price.component';
import {FormsModule} from '@angular/forms';
import { CatalogFilterYearComponent } from './components/catalog-filter-year/catalog-filter-year.component';
import { CatalogFilterRangeComponent } from './components/catalog-filter-range/catalog-filter-range.component';
import { CatalogFilterQuantityComponent } from './components/catalog-filter-quantity/catalog-filter-quantity.component';
import { CatalogFilterOwnerCountComponent } from './components/catalog-filter-owner-count/catalog-filter-owner-count.component';
import { CatalogFilterBtnToggleComponent } from './components/catalog-filter-btn-toggle/catalog-filter-btn-toggle.component';
import { CatalogFilterCharacteristicsComponent } from './components/catalog-filter-characteristics/catalog-filter-characteristics.component';
import { CatalogFilterColorsComponent } from './components/catalog-filter-colors/catalog-filter-colors.component';
import { CatalogFilterCheckboxComponent } from './components/catalog-filter-checkbox/catalog-filter-checkbox.component';
import { CatalogCarDetailComponent } from './components/catalog-car-detail/catalog-car-detail.component';
import {SwiperModule} from 'swiper/angular';
import { CatalogFilterBlockComponent } from './components/catalog-filter-block/catalog-filter-block.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { CatalogSortComponent } from './components/catalog-sort/catalog-sort.component';
import { CatalogSortButtonComponent } from './components/catalog-sort-button/catalog-sort-button.component';


@NgModule({
  declarations: [
    CatalogPageComponent,
    HeaderAppDownloadBannerComponent,
    CatalogListComponent,
    CatalogListCardComponent,
    CatalogFilterComponent,
    CatalogFilterModelComponent,
    CatalogFilterModelDropdownComponent,
    CatalogFilterPriceComponent,
    CatalogFilterYearComponent,
    CatalogFilterRangeComponent,
    CatalogFilterQuantityComponent,
    CatalogFilterOwnerCountComponent,
    CatalogFilterBtnToggleComponent,
    CatalogFilterCharacteristicsComponent,
    CatalogFilterColorsComponent,
    CatalogFilterCheckboxComponent,
    CatalogCarDetailComponent,
    CatalogFilterBlockComponent,
    CatalogSortComponent,
    CatalogSortButtonComponent
  ],
  imports: [
    SharedModule,
    ApiModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: CatalogPageComponent,
      },
    ]),
    NgbModule,
    SwiperModule,
    InfiniteScrollModule,
  ],
})
export class CatalogModule {
}
