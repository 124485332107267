<div class="view-switcher">
  <button
    [class]="{'btn-rect': true, 'active': currentSection === 'map'}"
    (click)="openSection('map')"
  >
    <span class="d-none d-md-inline">Карта</span>
    <svg class="d-md-none" width="19" height="19">
      <use xlink:href="#i-switcher-map"></use>
    </svg>
  </button>
  <button
    [class]="{'btn-rect': true,  'active': currentSection === 'list'}"
    (click)="openSection('list')"
  >
    <span class="d-none d-md-inline">Список</span>
    <svg class="d-md-none" width="18" height="14">
      <use xlink:href="#i-switcher-list"></use>
    </svg>
  </button>
</div>
