import {Component, ElementRef, OnInit} from '@angular/core';
import {create, API as NoUiSlider} from 'nouislider';
import {Logger} from '../../../shared/helpers/logger';
import {CarFilterService} from '../../services/car-filter.service';
import {IRangeValue} from '../../../shared/interfaces/range-value.interface';

@Component({
  selector: 'app-catalog-filter-price',
  templateUrl: './catalog-filter-price.component.html',
  styleUrls: ['./catalog-filter-price.component.scss']
})
export class CatalogFilterPriceComponent implements OnInit {
  private readonly logger = new Logger(CatalogFilterPriceComponent.name);

  isCollapsed = false;
  minValue = 0;
  maxValue = 3000000;

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
  }

  updateFilter(value: IRangeValue): void {
    this.carFilterService.setPriceRange(value);
  }
}
