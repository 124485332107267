import {Component, Input, OnInit} from '@angular/core';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {DealersService} from '../../services/dealers.service';

@Component({
  selector: 'app-geography-city-select',
  templateUrl: './geography-city-select.component.html',
  styleUrls: ['./geography-city-select.component.scss']
})
export class GeographyCitySelectComponent implements OnInit {
  @Input()
  cities: IDropdownOption<number>[];

  opened = false;
  selectedValueLabel = '';

  constructor(
    private readonly dealersService: DealersService
  ) {
  }

  ngOnInit(): void {
    this.dealersService.cityDealers$.subscribe(response => {
      this.cities = response.map((item, index) => ({
        id: item.id,
        value: item.id,
        name: item.name,
        selected: index === 0,
      }));

      this.selectCityById(0);
    });
  }

  selectCity(city: IDropdownOption<number>): void {
    this.cities.forEach(c => c.selected = city.value === c.value);
    this.selectedValueLabel = city.name;
    this.dealersService.setCurrentCity(city.value);
  }

  selectCityById(id: number): void {
    const city = this.cities.find(c => c.value === id);
    this.selectCity(city);
  }

  handleOpenChanged(open: boolean): void {
    this.opened = open;
  }

  trackOption(index: number, option: IDropdownOption<number>): number {
    return option.value;
  }
}

