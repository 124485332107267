import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {HomeModule} from './home/home.module';
import {CatalogModule} from './catalog/catalog.module';
import {DocumentsModule} from './documents/documents.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HomeModule,
    CatalogModule,
    DocumentsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
