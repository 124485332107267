import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-catalog-filter-quantity',
  templateUrl: './catalog-filter-quantity.component.html',
  styleUrls: ['./catalog-filter-quantity.component.scss']
})
export class CatalogFilterQuantityComponent implements OnInit {
  @Input()
  minValue: number;

  @Input()
  maxValue: number;

  @Input()
  step = 1;

  @Output()
  change = new EventEmitter<number>();

  value = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  add(): void {
    let newValue = this.value + this.step;

    if (newValue > this.maxValue) {
      newValue = this.maxValue;
    }

    this.set(newValue);
  }

  sub(): void {
    let newValue = this.value - this.step;

    if (newValue < this.minValue) {
      newValue = this.minValue;
    }

    this.set(newValue);
  }

  set(value: number): void {
    this.value = value;
    this.change.emit(value);
  }
}
