import {Component, Input, OnInit} from '@angular/core';
import {IHomeCatalogCard} from '../../interfaces/home-catalog-card.interface';

@Component({
  selector: 'app-catalog-slider-card',
  templateUrl: './catalog-slider-card.component.html',
  styleUrls: ['./catalog-slider-card.component.scss']
})
export class CatalogSliderCardComponent implements OnInit {
  @Input()
  card: IHomeCatalogCard;

  constructor() {
  }

  ngOnInit(): void {
  }

}
