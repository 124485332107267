<div class="map-list-wrapper">
  <ul class="map-list">
    <li
      class="dealer-item"
      *ngFor="let dealer of dealers"
      (click)="handleDealerClick(dealer)"
    >
      <div class="dealer-item-title">{{dealer.dealerName}}</div>
      <div class="dealer-item-text">{{dealer.dealerAddress}}</div>
    </li>
  </ul>
</div>
