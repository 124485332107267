<app-modal id="app-modal" modalClass="modal-light modal-download-app">
  <ng-container modalDialog>
    <div class="modal-dialog container-fluid" role="document">
      <div class="modal-content">
        <div class="modal-close-wrapper">
          <button type="button" class="close btn-img" data-dismiss="modal" aria-label="Close" appModalTrigger modalId="app-modal">
            <svg>
              <use xlink:href="#i-close-modal"></use>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="modal-title">Скачать приложение</h5>
          <div class="modal-download-app-links">
            <div class="modal-download-app-link">
              <a href="https://play.google.com/store/apps/details?id=com.hyundai.mobility.uka" target="_blank"><img
                src="/assets/images/icons/i-google-play-black.svg" alt="Google Play"></a>
            </div>
            <div class="modal-download-app-link">
              <a href="https://apps.apple.com/ru/app/uka/id1546183045" target="_blank"><img
                src="/assets/images/icons/i-app-store-black.svg" alt="App Store"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-modal>
