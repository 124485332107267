import {Injectable} from '@angular/core';
import {ApiCarsService} from '../../api/services/api-cars.service';
import {IApiCarsResponse} from '../../api/interfaces/api-cars.response.interface';
import {ReplaySubject, Subscription} from 'rxjs';
import {CarFilterService} from './car-filter.service';
import {IApiCarsRequest} from '../../api/interfaces/api-cars.request.interface';
import {Logger} from '../../shared/helpers/logger';

@Injectable({
  providedIn: 'root'
})
export class CarListService {
  private logger = new Logger(CarListService.name);

  public carList$ = new ReplaySubject<IApiCarsResponse>();

  private carList: IApiCarsResponse;
  private filterSubscription: Subscription;
  private loadCarsTimer: number = null;
  private loadCarsDelay = 300;

  private isFilteredListLoading = false;
  private isNextPageLoading = false;
  private hasNextPage = true;

  constructor(
    private readonly carFilterService: CarFilterService,
    private readonly apiCarsService: ApiCarsService
  ) {
    this.filterSubscription = carFilterService.filter$.subscribe(filter => this.filterModels(filter));
  }

  public filterModels(filter: IApiCarsRequest): void {
    if (this.loadCarsTimer) {
      clearTimeout(this.loadCarsTimer);
    }

    this.loadCarsTimer = setTimeout(() => {
      this.isFilteredListLoading = true;
      const subscription = this.apiCarsService.getCars(filter).subscribe(response => {
        this.isFilteredListLoading = false;
        this.pushCarListResponse(response);
        subscription.unsubscribe();
      });
    }, this.loadCarsDelay);
  }

  public loadMoreModels(): void {
    if (!this.carList) {
      return;
    }

    const filter = this.carFilterService.getFilter();
    const skip = this.carList.items.length;
    const hasNextPage = skip < this.carList.total;

    if (!hasNextPage || this.isNextPageLoading || this.isFilteredListLoading) {
      return;
    }

    if (this.loadCarsTimer) {
      clearTimeout(this.loadCarsTimer);
    }

    this.isNextPageLoading = true;
    const subscription = this.apiCarsService.getCars(filter, skip).subscribe(response => {
      this.isNextPageLoading = false;
      const carList: IApiCarsResponse = {
        items: this.carList.items.concat(response.items),
        total: response.total,
        carsTotal: response.carsTotal,
      };

      this.pushCarListResponse(carList);
      subscription.unsubscribe();
    });
  }

  private pushCarListResponse(newValue: IApiCarsResponse): void {
    this.carList = newValue;
    this.carList$.next(this.carList);
  }
}
