<div class="filter-checkbox">
  <label [for]="id" class="checkbox-wrapper filter-checkbox-wrapper">
    <input [id]="id" type="checkbox" class="checkbox-input" [(ngModel)]="value" (ngModelChange)="handleChange()">
    <span class="checkbox-box"><svg><use xlink:href="#i-check"></use></svg></span>
    <span class="checkbox-title">
      <span>{{title}}</span>
      <ng-content select="[afterTitle]"></ng-content>
    </span>
  </label>
  <ng-content select="[afterLabel]"></ng-content>
</div>
