<header>
  <div class="container">
    <nav class="navbar">
      <a [routerLink]="['/']" class="header-logo">
        <svg>
          <use xlink:href="#i-logo"></use>
        </svg>
      </a>
      <div class="nav-menu-wrapper ml-auto">
        <ul class="nav-menu d-none d-lg-flex">
          <li><a [routerLink]="['/catalog']" routerLinkActive="active" class="btn">Каталог</a></li>
          <li><a [routerLink]="['/documents']" routerLinkActive="active" class="btn">Документы</a></li>
<!--          <li><a [routerLink]="['/contacts']" routerLinkActive="active" class="btn">Контакты</a></li>-->
        </ul>
        <button class="btn btn-img btn-burger d-flex d-lg-none">
          <svg focusable="false">
            <title>Menu</title>
            <use xlink:href="#i-burger"></use>
          </svg>
        </button>
      </div>
    </nav>
  </div>
</header>
