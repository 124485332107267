import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ModalTriggerDirective } from './directives/modal-trigger.directive';
import { NumberDelimiterPipe } from './pipes/number-delimiter.pipe';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { CitySelectComponent } from './components/city-select/city-select.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { HighlightMatchingPipe } from './pipes/highlight-matching.pipe';
import {ApiModule} from '../api/api.module';
import { DownloadAppBtnDirective } from './directives/download-app-btn.directive';



@NgModule({
  declarations: [
    ModalComponent,
    ModalTriggerDirective,
    NumberDelimiterPipe,
    ArrayFilterPipe,
    CitySelectComponent,
    HighlightMatchingPipe,
    DownloadAppBtnDirective,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ApiModule,
  ],
  exports: [
    CommonModule,
    ModalComponent,
    ModalTriggerDirective,
    NumberDelimiterPipe,
    ArrayFilterPipe,
    CitySelectComponent,
    DownloadAppBtnDirective,
  ],
})
export class SharedModule { }
