import {Component, OnInit} from '@angular/core';
import SwiperCore, {Swiper, Thumbs, Navigation, Autoplay, SwiperOptions} from 'swiper';

SwiperCore.use([Thumbs, Navigation, Autoplay]);

@Component({
  selector: 'app-guide-slider',
  templateUrl: './guide-slider.component.html',
  styleUrls: ['./guide-slider.component.scss']
})
export class GuideSliderComponent implements OnInit {
  private mainSlider: Swiper;
  private thumbsSlider: Swiper;
  private progressUpdateInterval = 10;
  private progressUpdateTimer: number;
  private lastSlideTime = 0;
  private delay = 6000;

  private mainSliderConfig: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: false,
    speed: 1200,
    autoplay: {
      delay: this.delay,
      stopOnLastSlide: true,
      disableOnInteraction: false,
    },
    navigation: {
      prevEl: '.guide-section .swiper-button-prev',
      nextEl: '.guide-section .swiper-button-next',
      disabledClass: 'd-none',
    },
  };

  private thumbsSliderConfig: SwiperOptions = {
    slidesPerView: 2,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      992: {
        slidesPerView: 6,
        allowTouchMove: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      },
    },
  };

  constructor() {
  }

  ngOnInit(): void {
    this.initThumbsSlider();
    this.initMainSlider();
  }

  initMainSlider(): void {
    if (!this.thumbsSlider) {
      console.error(`thumbsSlider not defined`);
    }

    const thumbConfig: SwiperOptions = {
      thumbs: {swiper: this.thumbsSlider},
    };
    const config = Object.assign(this.mainSliderConfig, thumbConfig);
    this.mainSlider = new Swiper('.guide-slider', config);
    // this.mainSlider.autoplay.stop();

    /* @ts-ignore */
    if (this.mainSlider.initialized) {
      this.afterMainSliderInit();
    }

    this.mainSlider.on('afterInit', this.afterMainSliderInit.bind(this));
    this.mainSlider.on('slideChange', this.handleSlideChanged.bind(this));
    this.mainSlider.on('slideChangeTransitionEnd', this.startInterval.bind(this));
    this.mainSlider.on('autoplayStart', this.startInterval.bind(this));
    this.mainSlider.on('autoplayStop', this.resetProgressBar.bind(this));
  }

  initThumbsSlider(): void {
    this.thumbsSlider = new Swiper('.guide-slider-thumbs', this.thumbsSliderConfig);
    this.thumbsSlider.on('breakpoint', this.handleThumbsSliderResize.bind(this));
  }

  handleThumbsSliderResize(swiper: Swiper, breakpointParams: SwiperOptions): void {
    if (breakpointParams.slidesPerView === 'auto') {
      swiper.slides.forEach(slide => {
        (slide as HTMLElement).style.width = '';
      });
      swiper.update();
    }
  }

  private afterMainSliderInit(): void {
    this.handleSlideChanged(this.mainSlider);
    this.startInterval();
  }

  private handleSlideChanged(swiper: Swiper): void {
    const slideNum = swiper.realIndex + 1;
    this.setProgressActive(slideNum);
  }

  private setProgressActive(slideNum: number): void {
    this.resetProgressBar();

    document.querySelectorAll('.guide-slider-progress-item').forEach((item, key) => {
      const progress = item.querySelector('.guide-slider-progress-item-value') as HTMLElement;
      if (progress) {
        if (key + 1 < slideNum) {
          progress.style.width = '100%';
        } else {
          progress.style.width = '0';
        }

        progress.classList.remove('progress');
      }

      item.classList.remove('active');
    });

    const activeProgressItem = document.querySelector(`.guide-slider-progress-item:nth-child(${slideNum})`);

    if (activeProgressItem) {
      activeProgressItem.classList.add('active');
      const activeProgressBar = activeProgressItem.querySelector('.guide-slider-progress-item-value');
      if (activeProgressBar) {
        activeProgressBar.classList.add('progress');
      }
    }
  }

  private handleVisibleEvent(el, visible): void {
    if (visible) {
      this.mainSlider.autoplay.start();
    } else {
      this.mainSlider.autoplay.stop();
    }
  }

  private startInterval(): void {
    this.resetProgressBar();
    this.progressUpdateTimer = setInterval(this.updateProgressBar.bind(this), this.progressUpdateInterval);
  }

  private updateProgressBar(): void {
    const progressBar = document.querySelector('.guide-slider-progress-item-value.progress') as HTMLElement;
    if (progressBar && this.mainSlider.autoplay.running) {
      this.lastSlideTime += this.progressUpdateInterval;
      const current = Math.round(this.lastSlideTime / this.delay * 10000) / 100;
      progressBar.style.width = `${current}%`;
    }
  }

  private resetProgressBar(): void {
    this.lastSlideTime = 0;
    if (this.progressUpdateTimer) {
      clearInterval(this.progressUpdateTimer);
    }
  }
}
