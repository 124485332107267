import {Component, OnInit} from '@angular/core';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {Logger} from '../../../shared/helpers/logger';
import {ReplaySubject} from 'rxjs';
import {IApiModel} from '../../../api/interfaces/api-model.interface';
import {CarFilterService} from '../../services/car-filter.service';
import {ModelsService} from '../../../shared/services/models.service';
import {last} from 'rxjs/operators';
import {CarFilterModelService} from '../../services/car-filter-model.service';

@Component({
  selector: 'app-catalog-filter-model',
  templateUrl: './catalog-filter-model.component.html',
  styleUrls: ['./catalog-filter-model.component.scss']
})
export class CatalogFilterModelComponent implements OnInit {
  public options: IDropdownOption<number>[] = [];

  constructor(
    private readonly carFilterModelService: CarFilterModelService
  ) {
  }

  ngOnInit(): void {
    this.carFilterModelService.options$.subscribe(options => this.options = options);
    this.carFilterModelService.tryInit();
  }

  filterSelectedOptions(option: IDropdownOption<number>): boolean {
    return option.selected;
  }

  toggleItemSelection(option: IDropdownOption<number>): void {
    option.selected = !option.selected;
    this.carFilterModelService.options$.next(this.options);
  }

  setOptions(options: IDropdownOption<number>[]): void {
    this.carFilterModelService.options$.next(options);
  }
}
