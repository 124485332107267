import {Directive, HostListener, Input} from '@angular/core';
import {ModalService} from '../services/modal.service';

@Directive({
  selector: '[appDownloadAppBtn]'
})
export class DownloadAppBtnDirective {
  @Input()
  modalId: string;

  constructor(
    private readonly modalService: ModalService
  ) {
  }

  @HostListener('click')
  handleClick(): void {
    let gadget;
    /* @ts-ignore */
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // asButton.hide();
      gadget = 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // gpButton.hide();
      gadget = 'ios';
    } else {
      gadget = 'undefined';
    }

    if (gadget === 'ios') {
      window.open('https://apps.apple.com/ru/app/uka/id1546183045', '_blank');
    } else if (gadget === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.hyundai.mobility.uka', '_blank');
    } else {
      this.modalService.open(this.modalId);
    }
  }
}
