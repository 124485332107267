import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberDelimiter'
})
export class NumberDelimiterPipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {
    let strVal: string;
    if (value !== null) {
      strVal = typeof value === 'string' ? value : value.toString();
    } else {
      strVal = '0';
    }
    return strVal.split('').reverse().join('').match(/\d{0,3}/g).join(' ').split('').reverse().join('');
  }

}
