import {Component, OnInit} from '@angular/core';
import {CityService} from '../../../shared/services/city.service';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {DefaultCityId} from '../../../shared/consts/default-city-id.const';
import {Logger} from '../../../shared/helpers/logger';
import {ModelsService} from '../../../shared/services/models.service';
import {IHomeCatalogCard} from '../../interfaces/home-catalog-card.interface';
import {SwiperOptions} from 'swiper';
import {Router} from '@angular/router';
import Swiper from 'swiper/types/swiper-class';

@Component({
  selector: 'app-catalog-slider',
  templateUrl: './catalog-slider.component.html',
  styleUrls: ['./catalog-slider.component.scss']
})
export class CatalogSliderComponent implements OnInit {
  private readonly logger = new Logger(CatalogSliderComponent.name);

  cities: IDropdownOption<number>[] = [];
  models: IHomeCatalogCard[] = [];

  sliderConfig: SwiperOptions = {
    slidesPerView: 'auto',
    breakpoints: {
      992: {
        slidesPerView: 4,
        allowSlideNext: false,
        allowSlidePrev: false,
        allowTouchMove: false,
      },
    }
  };

  constructor(
    private readonly cityService: CityService,
    private readonly modelService: ModelsService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.cityService.loadCities();
    this.cityService.cities$.subscribe(response => {
      this.cities = response?.items?.map(item => ({
        id: item.id,
        name: item.name,
        value: item.id,
        selected: item.id === this.cityService.getSelectedCityId(),
      }));
      this.handleChangeCity(this.cityService.getSelectedCityId());
    });

    this.modelService.models$.subscribe(response => {
      this.models = response?.map(item => ({
        id: item.id,
        title: item.modelName,
        photo: item.modelPhoto,
        price: item.minPrice,
        count: item.carCountByModel,
      }));
      this.logger.log(response);
    });
  }

  handleChangeCity(cityId: number): void {
    this.cityService.setSelectedCity(cityId);
    this.modelService.loadModels();
  }

  goToModelCatalog(model: IHomeCatalogCard): void {
    this.modelService.setHomeSelectedModel(model.id);
    this.router.navigate(['/catalog']).then();
  }

  trackModel(index: number, model: IHomeCatalogCard): number {
    return model.id;
  }

  handleResizeEvent(swiper: Swiper): void {
    const breakpoint = swiper.currentBreakpoint;

    if (breakpoint === 'max') {
      swiper.slides.forEach(slide => {
        (slide as HTMLElement).style.width = '';
      });
    }

    swiper.update();
  }
}
