<div ngbDropdown #cityDropdown="ngbDropdown" class="filter-city position-relative">
  <div class="current" (click)="openDropdown()">
    <svg>
      <use xlink:href="#i-map-marker"></use>
    </svg>
    <span>{{selectedName}}</span>
  </div>
  <div class="filter-city-container" ngbDropdownMenu>
    <div class="filter-city-modal-header-fixed">
      <div class="container d-flex align-items-center">
        <div class="filter-city-modal-title">Где будем искать?</div>
        <div class="filter-city-modal-close-wrapper ml-auto">
          <button type="button" class="close btn-img" data-dismiss="modal" aria-label="Close" (click)="closeDropdown()">
            <svg>
              <use xlink:href="#i-close-modal"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="filter-city-input-wrapper">
      <div class="filter-city-input">
        <input type="text" class="filter-input" placeholder="Начните вводить город" [(ngModel)]="search">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.625 13.625C10.9387 13.625 13.625 10.9387 13.625 7.625C13.625 4.31129 10.9387 1.625 7.625 1.625C4.31129 1.625 1.625 4.31129 1.625 7.625C1.625 10.9387 4.31129 13.625 7.625 13.625Z"
            stroke="black" stroke-width="2"/>
          <path d="M12 12L16 16" stroke="black" stroke-width="2"/>
        </svg>
      </div>
    </div>
    <div class="filter-city-options">
      <div
        *ngFor="let option of options | arrayFilter:filterCityCallback:search; trackBy: trackOption"
        class="filter-city-option"
        [innerHTML]="option.name | highlightMatching:search"
        (click)="selectCity(option)"
      ></div>
    </div>
    <div class="filter-city-footer">
      <button class="filter-btn" (click)="closeDropdown()">Отмена</button>
    </div>
  </div>
</div>
