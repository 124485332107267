import {Component, ElementRef, OnInit} from '@angular/core';
import SwiperCore, {Swiper, SwiperOptions, Thumbs, Navigation} from 'swiper';
import {Logger} from '../../../shared/helpers/logger';

SwiperCore.use([Thumbs, Navigation]);

@Component({
  selector: 'app-modal-download-app',
  templateUrl: './modal-download-app.component.html',
  styleUrls: ['./modal-download-app.component.scss']
})
export class ModalDownloadAppComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
