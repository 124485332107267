<section class="home-catalog-section">
  <div class="container">
    <div class="section-title">Каталог моделей</div>
    <div class="home-catalog-location">
      <app-city-select
        [options]="cities"
        (changeCity)="handleChangeCity($event)"
      ></app-city-select>
    </div>
  </div>
  <div class="home-catalog-slider">
    <div class="container-fluid container-lg">
      <swiper
        [config]="sliderConfig"
        (breakpoint)="handleResizeEvent($event)"
      >
        <ng-template swiperSlide *ngFor="let model of models; trackBy: trackModel">
          <app-catalog-slider-card
            [card]="model"
            (click)="goToModelCatalog(model)"
          ></app-catalog-slider-card>
        </ng-template>
      </swiper>
    </div>
  </div>
  <div class="container">
    <div class="btn-more-wrapper">
      <a [routerLink]="['/catalog']" class="btn-more">Весь каталог
        <svg>
          <use xlink:href="#i-button-arrow"></use>
        </svg>
      </a>
    </div>
  </div>
</section>
