/**
 *
 * @param num Число на основе которого нужно выбрать склонение
 * @param form1 Склонение слова для 1 - 'яблоко'
 * @param form2 Склонение слова для 2 - 'яблока'
 * @param form5 Склонение слова для 5 - 'яблок'
 */
export function selectPluralForm(num: number, [form1, form2, form5]: string[]): string {
  const lastDigits = num % 100;
  let correctForm: string;

  if (lastDigits >= 11 && lastDigits <= 19) {
    correctForm = form5;
  } else {
    const lastDigit = num % 10;

    switch (lastDigit) {
      case 1:
        correctForm = form1;
        break;
      case 2:
      case 3:
      case 4:
        correctForm = form2;
        break;
      default:
        correctForm = form5;
    }
  }

  return correctForm;
}
