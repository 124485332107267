import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IApiCitiesResponse} from '../interfaces/api-cities.response.interface';
import {getApiUrl} from '../helpers/get-api-url.function';
import {Logger} from '../../shared/helpers/logger';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiCitiesService {
  private readonly logger = new Logger(ApiCitiesService.name);

  constructor(
    private readonly http: HttpClient
  ) {
  }

  getCities(): Observable<IApiCitiesResponse> {
    const url = getApiUrl('/cities/enabled');
    return this.http.get<IApiCitiesResponse>(url).pipe(
      tap(response => this.logger.log(response))
    );
  }
}
