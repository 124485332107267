<div ngbDropdown class="multiselect-actions">
  <div class="btn btn-icon btn-transparent-sand js-add-more-model" ngbDropdownToggle>
    <svg>
      <use xlink:href="#i-plus-filter"></use>
    </svg>
    <span>Добавить модель</span>
  </div>
  <div class="link-clear-filter js-clear-filer" (click)="clearSelections()">
    <svg>
      <use xlink:href="#i-close-filter"></use>
    </svg>
    <span>Сбросить все</span>
  </div>
  <div class="multiselect-options-container" ngbDropdownMenu>
    <div
      *ngFor="let option of options; trackBy: trackOption"
      [class]="{'multiselect-option': true, selected: option.selected}"
      (click)="toggleItemSelection(option)"
    >
      <span class="multiselect-option-box">
        <svg><use xlink:href="#i-check"></use></svg>
      </span>
      <span class="multiselect-option-name">{{option.name}}</span>
    </div>
  </div>
</div>
