import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() modalClass = '';
  @Input() id: string;

  opened = false;

  constructor(
    private readonly modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
  }

  open(): void {
    this.opened = true;
    document.body.classList.add('modal-open');
  }

  close(): void {
    this.opened = false;
    document.body.classList.remove('modal-open');
  }

  get classes(): string {
    return this.modalClass + (this.opened ? ' opened' : '');
  }
}
