import {Component, Input, OnInit} from '@angular/core';
import {ICatalogListCard} from '../../interfaces/catalog-list-card.interface';
import {CatalogCarDetailComponent} from '../catalog-car-detail/catalog-car-detail.component';
import {selectPluralForm} from '../../../shared/helpers/select-plural-form.function';

@Component({
  selector: 'app-catalog-list-card',
  templateUrl: './catalog-list-card.component.html',
  styleUrls: ['./catalog-list-card.component.scss']
})
export class CatalogListCardComponent implements OnInit {
  @Input() carCard: ICatalogListCard;

  @Input() carDetailComponent: CatalogCarDetailComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  showDetail(): void {
    this.carDetailComponent.setCar(this.carCard.detail);
    this.carDetailComponent.show();
  }

  getOwnerLabel(): string {
    const {ownerCount} = this.carCard;
    const pluralForm = selectPluralForm(ownerCount, ['Владелец', 'Владельца', 'Владельцев']);

    return `${ownerCount} ${pluralForm}`;
  }
}
