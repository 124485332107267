import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-catalog-filter-block',
  templateUrl: './catalog-filter-block.component.html',
  styleUrls: ['./catalog-filter-block.component.scss']
})
export class CatalogFilterBlockComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  isCollapsed = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
