<div
  ngbDropdown
  [class]="{'dropdown-wrapper': true, 'active': opened}"
  (openChange)="handleOpenChanged($event)"
>
  <div class="dropdown-selected-line" ngbDropdownToggle>
    <div class="dropdown-selected">
      <span class="dropdown-selected-value">{{selectedValueLabel}}</span>
      <span class="dropdown-placeholder">Выберите город</span>
    </div>
    <div class="dropdown-switcher">
      <svg>
        <use xlink:href="#i-dropdown-arrow"></use>
      </svg>
    </div>
  </div>
  <div class="dropdown-options-wrapper" ngbDropdownMenu>
    <div class="dropdown-options">
      <div
        *ngFor="let option of cities; trackBy: trackOption"
        [class]="{'dropdown-option': true, 'selected': option.selected}"
        (click)="selectCity(option)"
        ngbDropdownItem
      >{{option.name}}</div>
    </div>
  </div>
</div>
