import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'highlightMatching'
})
export class HighlightMatchingPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {

  }

  transform(value: string, find: string, spanClass = 'matching'): unknown {
    if (!find) {
      return value;
    }

    const regExp = new RegExp(`(${find})`, 'ig');
    return this.sanitizer.bypassSecurityTrustHtml(value.replace(regExp, `<span class="${spanClass}">$1</span>`));
  }

}
