import {Component, OnInit} from '@angular/core';
import {CarFilterService} from '../../services/car-filter.service';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {Logger} from '../../../shared/helpers/logger';

@Component({
  selector: 'app-catalog-filter-characteristics',
  templateUrl: './catalog-filter-characteristics.component.html',
  styleUrls: ['./catalog-filter-characteristics.component.scss']
})
export class CatalogFilterCharacteristicsComponent implements OnInit {
  private readonly logger = new Logger(CatalogFilterCharacteristicsComponent.name);

  gearboxTypeOptions: IDropdownOption<number>[] = [];

  driveTypeOptions: IDropdownOption<number>[] = [];

  engineTypeOptions: IDropdownOption<number>[] = [];

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    this.carFilterService.availableFilter$.subscribe(availableFilter => {
      this.gearboxTypeOptions = availableFilter.gearId;
      this.driveTypeOptions = availableFilter.driveId;
      this.engineTypeOptions = availableFilter.engineTypeId;
    });
  }

  updateGearbox(value?: number): void {
    this.carFilterService.setGearType(value);
  }

  updateDriveType(value?: number): void {
    this.carFilterService.setDriveType(value);
  }

  updateEngineType(value?: number): void {
    this.carFilterService.setEngineType(value);
  }

  updateHasThirdSeat(value?: boolean): void {
    this.carFilterService.setHasThirdSeats(value);
  }

  updateHasMobikey(value?: boolean): void {
    this.carFilterService.setHasMobiKey(value);
  }
}
