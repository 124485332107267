import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter-btn-toggle',
  templateUrl: './catalog-filter-btn-toggle.component.html',
  styleUrls: ['./catalog-filter-btn-toggle.component.scss']
})
export class CatalogFilterBtnToggleComponent implements OnInit {
  @Input()
  options: IDropdownOption<number>[] = [];

  @Output()
  change = new EventEmitter<number>();

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    this.carFilterService.filterReset$.subscribe(() => this.reset());
  }

  toggleItem(option: IDropdownOption<number>): void {
    if (option.selected) {
      this.deselectItem(option);
    } else {
      this.selectItem(option);
    }

    const selectedItem = this.options.find(o => o.selected);
    this.change.emit(selectedItem?.value);
  }

  selectItem(option: IDropdownOption<number>): void {
    this.options.forEach(o => o.selected = o.value === option.value);
  }

  deselectItem(option: IDropdownOption<number>): void {
    option.selected = false;
  }

  trackOption(index: number, option: IDropdownOption<number>): number {
    return option.value;
  }

  private reset(): void {
    this.options.forEach(o => o.selected = false);
    this.change.emit();
  }
}
