<section class="ask-section">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="ask-section-title">У вас остались <br> вопросы?</div>
        <p>Напишите нам.</p>
      </div>
      <div class="col-12 col-md-6">
        <form action="#" id="contact-us">
          <div class="form-group">
            <input type="text" class="form-control" name="username" placeholder="Ваше имя" required>
          </div>
          <div class="form-group">
            <input type="tel" class="form-control" name="phone" placeholder="+7" required>
          </div>
          <div class="form-group">
            <input type="email" class="form-control" name="email" placeholder="Email" required>
          </div>
          <div class="form-group">
                        <textarea name="message" class="form-control" rows="3" placeholder="Сообщение"
                                  required></textarea>
          </div>
          <div class="row form-footer">
            <div class="col-12 col-xl-6">
              <label for="policy-check" class="checkbox-wrapper">
                <input type="checkbox" class="checkbox-input" name="policy" id="policy-check" required>
                <span class="checkbox-box"><svg><use xlink:href="#i-check"></use></svg></span>
                <span class="checkbox-title">Я согласен с <a href="/assets/docs/agreement.pdf"
                                                             target="_blank">правилами</a> обработки персональных данных</span>
              </label>
            </div>
            <div class="col-12 col-xl-6">
              <div class="btn-wrapper">
                <button type="submit" class="btn-rect btn-main-color">Задать вопрос</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
