<footer>
  <div class="container">
    <nav class="footer-navbar">
      <div class="footer-logo">
        <svg>
          <use xlink:href="#i-logo-hyundai"></use>
        </svg>
        <div class="copyrights d-md-none">2021 © ООО «Хендэ Мотор СНГ»</div>
      </div>
      <div class="nav-menu-wrapper">
        <ul class="nav-menu">
          <li><a [routerLink]="['/catalog']" routerLinkActive="active">Каталог</a></li>
          <li><a [routerLink]="['/documents']" routerLinkActive="active">Документы</a></li>
<!--          <li><a [routerLink]="['/contacts']" routerLinkActive="active">Контакты</a></li>-->
        </ul>
        <div class="copyrights d-none d-md-block">2021 © ООО «Хендэ Мотор СНГ»</div>
      </div>
      <div class="ml-md-auto">
        <div class="footer-app-links">
          <div class="footer-app-link">
            <a href="https://play.google.com/store/apps/details?id=com.hyundai.mobility.uka" target="_blank"><img
              src="/assets/images/icons/i-google-play.svg" alt="Google Play"></a>
          </div>
          <div class="footer-app-link">
            <a href="https://apps.apple.com/ru/app/uka/id1546183045" target="_blank"><img
              src="/assets/images/icons/i-app-store.svg" alt="App Store"></a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</footer>
