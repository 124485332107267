import {Component, OnInit} from '@angular/core';
import {ICatalogListCard} from '../../interfaces/catalog-list-card.interface';
import {IApiCarsResponse} from '../../../api/interfaces/api-cars.response.interface';
import {CarListService} from '../../services/car-list.service';
import {ReplaySubject, Subscription} from 'rxjs';
import {Logger} from '../../../shared/helpers/logger';

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss']
})
export class CatalogListComponent implements OnInit {
  cards$ = new ReplaySubject<ICatalogListCard[]>();

  private carListSubscription: Subscription;
  private readonly logger = new Logger(CatalogListComponent.name);

  constructor(private readonly carListService: CarListService) {
  }

  public ngOnInit(): void {
    this.carListSubscription = this.carListService.carList$.subscribe(res => this.handleCarListUpdated(res));
  }

  public handleCarListUpdated(carList: IApiCarsResponse): void {
    const cards: ICatalogListCard[] = carList.items.map(item => ({
      id: item.id,
      address: item.city?.name,
      mileage: item.mileage,
      name: item.modelName,
      year: item.year,
      ownerCount: item.ownerCount,
      price: item.ukaPrice,
      photo: item.photos?.[0],
      detail: item,
    }));

    this.cards$.next(cards);
  }

  public trackCard(index: number, card: ICatalogListCard): string {
    return card.id;
  }

  public handleScroll(): void {
    this.carListService.loadMoreModels();
    this.logger.log('load next page');
  }
}
