<section class="bg-sand mobikey-section">
  <div class="container">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-12 col-md-6 mobikey-img-wrapper">
        <img src="/assets/images/mobikey.png" alt="">
      </div>
      <div class="col-12 col-md-6 mobikey-content-wrapper">
        <div class="section-title">Цифровые ключи Mobikey</div>
        <p>У нас вы можете приобрести автомобили с цифровыми ключами. Mobikey позволяет управлять машиной из
          приложения.</p>
        <div class="btn-wrapper">
          <a href="https://www.hyundai.ru/mobikey" target="_blank" class="btn-link d-md-none">Узнать подробнее
            <svg>
              <use xlink:href="#i-button-arrow"></use>
            </svg>
          </a>
          <a href="https://www.hyundai.ru/mobikey" target="_blank"
             class="btn-rect btn-main-color d-none d-md-inline-block">Подробнее</a>
        </div>
      </div>
    </div>
  </div>
</section>
