import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-categories-slider',
  templateUrl: './car-categories-slider.component.html',
  styleUrls: ['./car-categories-slider.component.scss']
})
export class CarCategoriesSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
