import {Component, OnInit} from '@angular/core';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter-owner-count',
  templateUrl: './catalog-filter-owner-count.component.html',
  styleUrls: ['./catalog-filter-owner-count.component.scss']
})
export class CatalogFilterOwnerCountComponent implements OnInit {

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
  }

  updateFilter(value: number): void {
    this.carFilterService.setOwnerCount(value);
  }
}
