import {Injectable} from '@angular/core';
import {IScriptStoreItem} from '../interfaces/script-store-item.interface';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  private scriptStore: IScriptStoreItem[] = [];

  constructor() {
  }

  public processLoadScript(data: { name: string, src: string }): Promise<string> {
    return new Promise(((resolve, reject) => {
      const {name, src} = data;
      const existedItem = this.scriptStore.find(i => i.name === name);

      if (existedItem) {
        if (existedItem.loaded) {
          resolve(name);
        } else {
          reject('script loading');
        }
      } else {
        const scriptStoreItem: IScriptStoreItem = {
          name,
          src,
          loaded: false,
        };

        this.scriptStore.push(scriptStoreItem);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;

        /* @ts-ignore */
        if (script.readyState) {
          /* IE */
          /* @ts-ignore */
          script.onreadystatechange = () => {
            /* @ts-ignore */
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              /* @ts-ignore */
              script.onreadystatechange = null;
              scriptStoreItem.loaded = true;
              resolve(name);
            }
          };
        } else {
          /* Others */
          script.onload = () => {
            scriptStoreItem.loaded = true;
            resolve(name);
          };
        }
        script.onerror = (error: any) => reject('error during script loading');
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }));
  }
}
