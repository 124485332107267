<section class="header-banner-section">
  <div class="header-banner">
    <div class="header-banner-video">
      <video class="d-md-none" muted loop autoplay playsinline>
        <source src="/assets/video/hm_web_mob.mp4" type="video/mp4">
      </video>
      <video class="d-none d-md-block" muted loop autoplay playsinline>
        <source src="/assets/video/hm_web_desktop.mp4" type="video/mp4">
      </video>
    </div>
    <div class="container">
      <h1>Здесь о машинах <br> говорят правду</h1>
      <p class="subtitle">Смотрите историю автомобиля. <br> Покупайте онлайн.</p>
      <div class="header-banner-app-links mx-0">
        <button class="btn-rect btn-active-blue download-app-btn" type="button" data-toggle="modal"
                appDownloadAppBtn modalId="app-modal">
          Скачать приложение
        </button>
      </div>
    </div>
  </div>
  <div class="header-banner-footer bg-sand container-fluid">
    <div class="header-banner-app d-none d-md-block">
      <img src="/assets/images/header-uka-app.png?v=1.1" alt="UKA">
    </div>
    <div class="header-banner-content">
      UKA — официальный сервис Hyundai <br class="d-none d-md-block"> по продаже автомобилей с пробегом.
    </div>
    <div class="header-banner-app d-md-none">
      <img src="/assets/images/header-uka-app-mobile.png" alt="UKA">
    </div>
  </div>
</section>
