<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-calendar"></use>
  </svg>
  <span>Год выпуска</span>
</div>
<app-catalog-filter-range
  [minValue]="1995"
  [maxValue]="maxYear"
  [step]="1"
  (change)="updateFilter($event)"
></app-catalog-filter-range>

