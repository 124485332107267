import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DealersService} from '../../services/dealers.service';
import {GeographySection} from '../../const/geography-section.const';

@Component({
  selector: 'app-geography-section',
  templateUrl: './geography-section.component.html',
  styleUrls: ['./geography-section.component.scss']
})
export class GeographySectionComponent implements OnInit {
  currentSection: GeographySection = 'map';

  constructor(
    private readonly dealerService: DealersService
  ) {
  }

  ngOnInit(): void {
    this.dealerService.loadDealers();
    this.dealerService.gotoDealer$.subscribe(() => this.currentSection = 'map');
  }
}
