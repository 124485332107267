<div class="card-car card-car-home-catalog">
  <div class="card-content">
    <div class="car-title">
      <span class="car-name">{{card.title}}</span>
      <span class="count">{{card.count}}</span>
    </div>
    <div class="car-price">от {{card.price | numberDelimiter}} ₽</div>
  </div>
  <div class="card-img">
    <img [src]="card.photo" [alt]="card.title">
  </div>
</div>
