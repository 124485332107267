import {EventEmitter, Injectable} from '@angular/core';
import {IApiCarsRequest} from '../../api/interfaces/api-cars.request.interface';
import {Logger} from '../../shared/helpers/logger';
import {IRangeValue} from '../../shared/interfaces/range-value.interface';
import {ICatalogFilterAvailable} from '../interfaces/catalog-filter-available.interface';
import {CatalogDriveTypeNames} from '../consts/catalog-drive-type-names.const';
import {CatalogEngineTypeNames} from '../consts/catalog-engine-type-names.const';
import {CatalogGearNames} from '../consts/catalog-gear-names.const';
import {DefaultCityId} from '../../shared/consts/default-city-id.const';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {SortingDirection} from '../../shared/consts/sorting-direction.const';

@Injectable({
  providedIn: 'root'
})
export class CarFilterService {
  private readonly logger = new Logger(CarFilterService.name);
  private filter: IApiCarsRequest = {
    cityIds: [DefaultCityId],
  };
  private availableFilter: ICatalogFilterAvailable = {
    driveId: [
      {
        id: 1,
        name: CatalogDriveTypeNames.fwd,
        value: 1,
        selected: false,
      },
      {
        id: 2,
        name: CatalogDriveTypeNames.awd,
        value: 2,
        selected: false,
      },
      {
        id: 3,
        name: CatalogDriveTypeNames.rwd,
        value: 3,
        selected: false,
      },
    ],
    engineTypeId: [
      {
        id: 1,
        name: CatalogEngineTypeNames.petrol,
        value: 1,
        selected: false,
      },
      {
        id: 2,
        name: CatalogEngineTypeNames.diesel,
        value: 2,
        selected: false,
      },
    ],
    gearId: [
      {
        id: 1,
        name: CatalogGearNames.at,
        value: 1,
        selected: false,
      },
      {
        id: 2,
        name: CatalogGearNames.mt,
        value: 2,
        selected: false,
      },
      {
        id: 3,
        name: CatalogGearNames.amt,
        value: 3,
        selected: false,
      },
    ],
  };

  public filter$ = new ReplaySubject<IApiCarsRequest>();
  public availableFilter$ = new BehaviorSubject<ICatalogFilterAvailable>(this.availableFilter);
  public filterReset$ = new EventEmitter<void>();

  constructor() {
    this.filter$.subscribe(f => this.logger.log(f));
  }

  setModels(values: number[]): void {
    this.filter.modelIds = values;
    this.logger.log('setModels', values);
    this.filter$.next(this.filter);
  }

  setPriceRange(data: IRangeValue): void {
    this.filter.priceMin = data.min;
    this.filter.priceMax = data.max;
    this.filter$.next(this.filter);
  }

  setYearRange(data: IRangeValue): void {
    this.filter.issueFrom = data.min;
    this.filter.issueTo = data.max;
    this.filter$.next(this.filter);
  }

  setMileage(data: IRangeValue): void {
    /* no filter */
  }

  setOwnerCount(value: number): void {
    /* no filter */
  }

  setGearType(value: number = null): void {
    this.filter.gearId = value;
    this.filter$.next(this.filter);
  }

  setDriveTypes(value: number[] = null): void {
    this.filter.driveIds = value;
    this.filter$.next(this.filter);
  }

  setDriveType(value: number = null): void {
    this.filter.driveIds = value !== null ? [value] : null;
    this.filter$.next(this.filter);
  }

  setEngineType(value: number = null): void {
    this.filter.engineTypeId = value;
    this.filter$.next(this.filter);
  }

  setColors(value: number[] = null): void {
    this.filter.exteriorColorIds = value;
    this.filter$.next(this.filter);
  }

  setHasThirdSeats(value: boolean = null): void {
    this.filter.hasThirdRowSeats = value ? value : null;
    this.filter$.next(this.filter);
  }

  setHasMobiKey(value: boolean = null): void {
    this.filter.hasMobiKey = value ? value : null;
    this.filter$.next(this.filter);
  }

  setCity(value: number): void {
    this.filter.cityIds = [value];
    this.filter$.next(this.filter);
  }

  resetFilter(): void {
    this.filterReset$.emit();
  }

  getFilter(): IApiCarsRequest {
    return this.filter;
  }

  setSorting(field: string, direction: SortingDirection): void {
    this.filter.sortByField = field;
    this.filter.sortDirection = direction;
    this.filter$.next(this.filter);
  }
}
