<section class="geography-section">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="section-title">География</div>
      </div>
      <div class="col-12 col-md-6">
        Автомобили UKA проходят предпродажное <br> обслуживание и диагностику только <br> у официальных дилеров
        Hyundai.
      </div>
    </div>
  </div>
  <div class="map-wrapper">
    <div class="container-fluid">
      <div class="position-relative">
        <div class="map-filter">
          <app-geography-city-select></app-geography-city-select>
          <app-geography-toggle [currentSection]="currentSection" (changeSection)="currentSection = $event"></app-geography-toggle>
        </div>
        <app-geography-map
          [class.d-none]="currentSection !== 'map'"
        ></app-geography-map>
        <app-geography-list
          [class.d-none]="currentSection !== 'list'"
        ></app-geography-list>
      </div>
    </div>
  </div>
</section>
