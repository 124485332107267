import {Directive, HostListener, Input} from '@angular/core';
import {ModalService} from '../services/modal.service';
import {Logger} from '../helpers/logger';

@Directive({
  selector: '[appModalTrigger]'
})
export class ModalTriggerDirective {
  @Input() modalId: string;
  logger = new Logger(ModalTriggerDirective.name);

  constructor(private readonly modalService: ModalService) { }

  @HostListener('click') onClick(): void {
    this.logger.log(this.modalId);
    this.modalService.toggle(this.modalId);
  }
}
