<section class="bg-gray guide-section">
  <div class="container">
    <div class="position-relative">
      <div class="section-title">Как воспользоваться <br> сервисом</div>
      <div class="guide-slider swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Выберите автомобиль</strong></div>
                  <p>Скачайте приложение. Историю и диагностику автомобиля можно посмотреть только там. Выберите город
                    и найдите машину по вашим параметрам.
                  </p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-1.png" alt="01">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-1.png" alt="01">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Узнайте <br> его историю</strong></div>
                  <p>Мы делимся историей <br> автомобиля бесплатно.</p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-2.png" alt="02">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-2.png" alt="02">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Изучите результаты диагностики</strong></div>
                  <p>Диагностику текущего состояния автомобиля <br> проводят только официальные дилеры.
                  </p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-3.png" alt="03">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-3.png" alt="03">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Зарегистрируйтесь</strong></div>
                  <p>Это можно сделать <br> в приложении.</p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-4.png" alt="04">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-4.png" alt="04">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Оплатите автомобиль</strong></div>
                  <p>Сделайте это на сайте или в приложении <br> через безопасную сделку.</p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-5.png" alt="05">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-5.png" alt="05">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="row">
              <div class="col col-12 col-md-6 guide-slide-content">
                <div class="wrap">
                  <div class="guide-slide-title"><strong>Получите автомобиль</strong></div>
                  <p>Заберите свой автомобиль <br> у официального дилера.</p>
                </div>
              </div>
              <div class="col col-12 col-md-6 guide-slide-img">
                <img class="d-none d-md-block" src="/assets/images/guide-slider/guide-6.png" alt="06">
                <img class="d-md-none" src="/assets/images/guide-slider/guide-mb-6.png" alt="06">
              </div>
            </div>
          </div>
        </div>
        <div class="guide-slider-navigation">
          <div class="wrap">
            <div class="swiper-button-next guide-arrow">
              <svg>
                <use xlink:href="#i-slider-arrow"></use>
              </svg>
            </div>
            <div class="swiper-button-prev guide-arrow">
              <svg>
                <use xlink:href="#i-slider-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="guide-slider-progress">
    <div class="container">
      <div class="guide-slider-thumbs swiper-container">
        <div class="swiper-wrapper">
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">01</strong>
              <span class="guide-slider-progress-item-bar">
                                <span class="guide-slider-progress-item-value"></span>
                            </span>
            </div>
            <div class="guide-slider-progress-item-title">
              Выберите <br> автомобиль
            </div>
          </div>
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">02</strong>
              <span class="guide-slider-progress-item-bar">
                                <span class="guide-slider-progress-item-value"></span>
                            </span>
            </div>
            <div class="guide-slider-progress-item-title">
              Узнайте <br> его историю
            </div>
          </div>
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">03</strong>
              <span class="guide-slider-progress-item-bar">
                                <span class="guide-slider-progress-item-value"></span>
                            </span>
            </div>
            <div class="guide-slider-progress-item-title">
              Изучите результаты <br> диагностики
            </div>
          </div>
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">04</strong>
              <span class="guide-slider-progress-item-bar">
                                <span class="guide-slider-progress-item-value"></span>
                            </span>
            </div>
            <div class="guide-slider-progress-item-title">
              Зарегистрируйтесь
            </div>
          </div>
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">05</strong>
              <span class="guide-slider-progress-item-bar">
                                <span class="guide-slider-progress-item-value"></span>
                            </span>
            </div>
            <div class="guide-slider-progress-item-title">
              Оплатите <br> автомобиль
            </div>
          </div>
          <div class="guide-slider-progress-item swiper-slide">
            <div class="guide-slider-progress-item-top">
              <strong class="guide-slider-progress-item-num">06</strong>
            </div>
            <div class="guide-slider-progress-item-title">
              Получите <br> автомобиль
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
