import { NgModule } from '@angular/core';
import { DocumentPageComponent } from './pages/document-page/document-page.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';



@NgModule({
  declarations: [DocumentPageComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: DocumentPageComponent,
      },
    ]),
  ]
})
export class DocumentsModule { }
