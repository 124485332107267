<div class="page-wrapper bg-gray">
  <div class="container">
    <h1 class="page-title">Документы</h1>

    <ul class="documents-list">
      <li class="documents-list-item">
        <a href="/assets/docs/sale-rules.pdf" target="_blank">
          <span class="documents-list-item-title">Правила продажи</span>
          <span class="documents-list-item-icon"><svg><use xlink:href="#i-doc-arrow"></use></svg></span>
        </a>
      </li>
      <li class="documents-list-item">
        <a href="/assets/docs/terms-of-use.pdf" target="_blank">
          <span class="documents-list-item-title">Пользовательское соглашение</span>
          <span class="documents-list-item-icon"><svg><use xlink:href="#i-doc-arrow"></use></svg></span>
        </a>
      </li>
      <li class="documents-list-item">
        <a href="/assets/docs/agreement.pdf" target="_blank">
          <span class="documents-list-item-title">Согласие на обработку персональных данных</span>
          <span class="documents-list-item-icon"><svg><use xlink:href="#i-doc-arrow"></use></svg></span>
        </a>
      </li>
      <li class="documents-list-item">
        <a href="/assets/docs/privacy-policy.pdf" target="_blank">
          <span class="documents-list-item-title">Политика конфиденциальности</span>
          <span class="documents-list-item-icon"><svg><use xlink:href="#i-doc-arrow"></use></svg></span>
        </a>
      </li>
    </ul>
  </div>
</div>
