import {NgModule} from '@angular/core';
import {HeaderBannerComponent} from './components/header-banner/header-banner.component';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import { AdvantagesSliderComponent } from './components/advantages-slider/advantages-slider.component';
import {SwiperModule} from 'swiper/angular';
import { CarCategoriesSliderComponent } from './components/car-categories-slider/car-categories-slider.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { GuideSliderComponent } from './components/guide-slider/guide-slider.component';
import { CatalogSliderComponent } from './components/catalog-slider/catalog-slider.component';
import { MobikeyComponent } from './components/mobikey/mobikey.component';
import { GeographySectionComponent } from './components/geography-section/geography-section.component';
import { AskFormComponent } from './components/ask-form/ask-form.component';
import { CatalogSliderCardComponent } from './components/catalog-slider-card/catalog-slider-card.component';
import { GeographyMapComponent } from './components/geography-map/geography-map.component';
import { GeographyListComponent } from './components/geography-list/geography-list.component';
import { GeographyToggleComponent } from './components/geography-toggle/geography-toggle.component';
import { GeographyCitySelectComponent } from './components/geography-city-select/geography-city-select.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    HeaderBannerComponent,
    HomePageComponent,
    AdvantagesSliderComponent,
    CarCategoriesSliderComponent,
    SubscriptionComponent,
    GuideSliderComponent,
    CatalogSliderComponent,
    MobikeyComponent,
    GeographySectionComponent,
    AskFormComponent,
    CatalogSliderCardComponent,
    GeographyMapComponent,
    GeographyListComponent,
    GeographyToggleComponent,
    GeographyCitySelectComponent
  ],
  imports: [
    SharedModule,
    SwiperModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomePageComponent,
      },
    ]),
    NgbModule,
  ]
})
export class HomeModule {
}
