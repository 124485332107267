<section class="bg-sand subscription-section d-none">
  <div class="container">
    <div class="section-title">Хотите получать наши самые интересные предложения?</div>
    <div class="subscription-subtitle">Подпишитесь на рассылку</div>
    <div class="subscription-form">
      <form action="#">
        <div class="input-wrapper">
          <input type="email" class="subscription-input" name="email" placeholder="Email">
        </div>
        <div class="btn-wrapper">
          <button type="submit" class="btn-rect btn-main-color">Подписаться</button>
        </div>
      </form>
    </div>
  </div>
</section>
