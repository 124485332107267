<div class="card-car card-car-full" (click)="showDetail()">
  <div *ngIf="carCard.ownerCount !== null" class="card-badge">
    {{getOwnerLabel()}}
  </div>
  <div class="card-img">
    <img [src]="carCard.photo" [alt]="carCard.name">
  </div>
  <div class="card-content">
    <div class="car-title">
      <div class="car-name">{{carCard.name}}</div>
      <div class="car-price">{{carCard.price | numberDelimiter}} ₽</div>
    </div>
    <div class="car-info">
      <div class="car-year">
        <svg>
          <use xlink:href="#i-filter-calendar"></use>
        </svg>
        <span>{{carCard.year}} год</span>
      </div>
      <div class="car-millage">
        <svg>
          <use xlink:href="#i-filter-mileage"></use>
        </svg>
        <span>{{carCard.mileage | numberDelimiter}} км</span>
      </div>
    </div>
    <div class="car-place">
      <svg>
        <use xlink:href="#i-map-marker"></use>
      </svg>
      <span>{{carCard.address}}</span>
    </div>
  </div>
</div>
