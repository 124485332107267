import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {CarFilterService} from '../../services/car-filter.service';
import {Logger} from '../../../shared/helpers/logger';

@Component({
  selector: 'app-catalog-filter-model-dropdown',
  templateUrl: './catalog-filter-model-dropdown.component.html',
  styleUrls: ['./catalog-filter-model-dropdown.component.scss']
})
export class CatalogFilterModelDropdownComponent implements OnInit {
  private readonly logger = new Logger(CatalogFilterModelDropdownComponent.name);

  @Input()
  options: IDropdownOption<number>[] = [];

  @Output()
  change: EventEmitter<IDropdownOption<number>[]> = new EventEmitter<IDropdownOption<number>[]>();

  constructor(private readonly carFilterService: CarFilterService) {
  }

  ngOnInit(): void {
    this.carFilterService.filterReset$.subscribe(() => this.clearSelections());
  }

  trackOption(index: number, option: IDropdownOption<number>): string | number {
    return option.value;
  }

  toggleItemSelection(option: IDropdownOption<number>): void {
    option.selected = !option.selected;
    this.change.emit(this.options);
  }

  clearSelections(): void {
    this.options.forEach(option => option.selected = false);
    this.change.emit(this.options);
  }
}
