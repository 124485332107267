import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDropdownOption} from '../../interfaces/dropdown-option.interface';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {isMobile} from '../../helpers/is-mobile.function';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss']
})
export class CitySelectComponent implements OnInit {
  @Input()
  options: IDropdownOption<number>[] = [];

  @Output()
  changeCity = new EventEmitter<number>();

  @ViewChild('cityDropdown')
  dropdown: NgbDropdown;

  search: string;

  filterCityCallback: (o: IDropdownOption<number>) => boolean;

  constructor(
    private readonly elementRef: ElementRef
  ) {
    this.filterCityCallback = this.filterCity.bind(this);
  }

  ngOnInit(): void {
  }

  trackOption(index: number, option: IDropdownOption<number>): number {
    return option.value;
  }

  filterCity(option: IDropdownOption<number>): boolean {
    if (this.search) {
      return option.name.toLowerCase().includes(this.search.toLowerCase());
    } else {
      return true;
    }
  }

  selectCity(option: IDropdownOption<number>): void {
    this.options.forEach(o => o.selected = o.value === option.value);
    this.changeCity.emit(option.value);
    this.closeDropdown();
  }

  get selectedName(): string {
    const selectedOption = this.options?.find(o => o.selected);
    return selectedOption?.name || '';
  }

  openDropdown(): void {
    if (isMobile()) {
      const dropdownMenu = this.getDropdownMenuElement();

      if (dropdownMenu) {
        document.body.classList.add('modal-opened');
        dropdownMenu.classList.add('show');
      }
    } else {
      this.dropdown.open();
    }
  }

  closeDropdown(): void {
    if (isMobile()) {
      const dropdownMenu = this.getDropdownMenuElement();

      if (dropdownMenu) {
        document.body.classList.remove('modal-opened');
        dropdownMenu.classList.remove('show');
      }
    } else {
      this.dropdown.close();
    }
  }

  private getDropdownMenuElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('.filter-city-container');
  }
}
