import {Injectable} from '@angular/core';
import {ModalComponent} from '../components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: ModalComponent[] = [];

  constructor() {
  }

  add(modal: ModalComponent): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter(item => item.id !== id);
  }

  open(id: string): void {
    const modal = this.modals.find(item => item.id === id);
    modal?.open();
  }

  close(id: string): void {
    const modal = this.modals.find(item => item.id === id);
    modal?.close();
  }

  toggle(id: string): void {
    const modal = this.modals.find(item => item.id === id);

    if (modal?.opened) {
      modal?.close();
    } else {
      modal?.open();
    }
  }
}
