import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IApiModel} from '../interfaces/api-model.interface';
import {Logger} from '../../shared/helpers/logger';
import {tap} from 'rxjs/operators';
import {IApiCarsRequest} from '../interfaces/api-cars.request.interface';
import {makeQueryObject} from '../helpers/make-query-object.function';
import {getApiUrl} from '../helpers/get-api-url.function';
import {IApiModelsResponse} from '../interfaces/api-models.response.interface';
import {IApiModelsRequest} from '../interfaces/api-models.request.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiModelsService {
  private readonly logger = new Logger(ApiModelsService.name);

  constructor(private readonly http: HttpClient) {
  }

  public getModels(): Observable<IApiModelsResponse> {
    const url = getApiUrl('/models');

    return this.http.get<IApiModelsResponse>(url).pipe(
      tap(response => this.logger.log('getModels:', response))
    );
  }

  public filterModels(filter: IApiModelsRequest): Observable<IApiModel[]> {
    const url = getApiUrl('/models/filter');
    const params = makeQueryObject(filter);

    return this.http.get<IApiModel[]>(url, {params}).pipe(
      tap(response => this.logger.log('filterModels:', response))
    );
  }
}
