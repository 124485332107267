import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {API as NoUiSlider, create} from 'nouislider';
import {IRangeValue} from '../../../shared/interfaces/range-value.interface';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter-range',
  templateUrl: './catalog-filter-range.component.html',
  styleUrls: ['./catalog-filter-range.component.scss']
})
export class CatalogFilterRangeComponent implements OnInit {
  @Input()
  minValue = 0;

  @Input()
  maxValue = 3000000;

  @Input()
  step = 10000;

  @Output()
  change = new EventEmitter<IRangeValue>();

  selectedMinValue = this.minValue;
  selectedMaxValue = this.maxValue;

  uiSlider: NoUiSlider;

  constructor(
    private readonly eref: ElementRef,
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    const priceSliderElement = this.eref.nativeElement.querySelector('.range-slider');

    create(priceSliderElement, {
      start: [this.minValue, this.maxValue],
      connect: true,
      step: this.step,
      range: {
        min: this.minValue,
        max: this.maxValue,
      },
    });

    this.uiSlider = priceSliderElement.noUiSlider;

    this.uiSlider.on('update', this.handleSliderUpdate.bind(this));
    this.carFilterService.filterReset$.subscribe(() => this.reset());
  }

  handleSliderUpdate(values: string[]): void {
    const [minValue, maxValue] = values;
    const selectedMinValue = parseInt(minValue, 0);
    const selectedMaxValue = parseInt(maxValue, 0);

    this.updateSelectValues({
      min: selectedMinValue,
      max: selectedMaxValue,
    });
  }

  handleChangeMinInput(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    const selectedMinValue = parseInt(input.value, 0);
    this.updateSelectValues({min: selectedMinValue});
    this.updateSliderOptions();
  }

  handleChangeMaxInput(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    const selectedMaxValue = parseInt(input.value, 0);
    this.updateSelectValues({max: selectedMaxValue});
    this.updateSliderOptions();
  }

  updateSelectValues(data: IRangeValue): void {
    if (data.min !== null) {
      if (isNaN(data.min) || data.min < this.minValue) {
        this.selectedMinValue = this.minValue;
      } else {
        this.selectedMinValue = data.min;
      }
    }

    if (data.max !== null) {
      if (isNaN(data.min) || data.max > this.maxValue) {
        this.selectedMaxValue = this.maxValue;
      } else {
        this.selectedMaxValue = data.max;
      }
    }

    const selectedMinValue = this.selectedMinValue !== this.minValue ? this.selectedMinValue : null;
    const selectedMaxValue = this.selectedMaxValue !== this.maxValue ? this.selectedMaxValue : null;

    this.change.emit({
      min: selectedMinValue,
      max: selectedMaxValue,
    });
  }

  updateSliderOptions(): void {
    this.uiSlider.updateOptions({
      start: [
        this.selectedMinValue,
        this.selectedMaxValue,
      ],
    }, true);
  }

  private reset(): void {
    this.updateSelectValues({
      min: this.minValue,
      max: this.maxValue,
    });
    this.updateSliderOptions();
  }
}
