import {Component, OnInit} from '@angular/core';
import {SwiperOptions} from 'swiper';
import Swiper from 'swiper/types/swiper-class';

interface SwiperBreakpointOption {
  [width: number]: SwiperOptions;
}

@Component({
  selector: 'app-advantages-slider',
  templateUrl: './advantages-slider.component.html',
  styleUrls: ['./advantages-slider.component.scss']
})
export class AdvantagesSliderComponent implements OnInit {
  breakpoints: SwiperBreakpointOption = {
    992: {
      slidesPerView: 4,
      allowSlideNext: false,
      allowSlidePrev: false,
      allowTouchMove: false,
    },
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  handleResizeEvent(swiper: Swiper): void {
    const breakpoint = swiper.currentBreakpoint;

    if (breakpoint === 'max') {
      swiper.slides.forEach(slide => {
        (slide as HTMLElement).style.width = '';
      });
    }

    swiper.update();
  }
}
