<div class="catalog-filter">

  <app-catalog-filter-block title="Модель">
    <ng-container filterBlockBody>
      <app-catalog-filter-model></app-catalog-filter-model>
    </ng-container>
  </app-catalog-filter-block>

  <app-catalog-filter-block title="Стоимость">
    <ng-container filterBlockBody>
      <app-catalog-filter-price></app-catalog-filter-price>
    </ng-container>
  </app-catalog-filter-block>

  <app-catalog-filter-block title="Возраст и пробег">
    <ng-container filterBlockBody>
      <app-catalog-filter-year></app-catalog-filter-year>
      <ng-template *ngIf="false">
        <div class="filter-item-subtitle">
          <svg>
            <use xlink:href="#i-filter-mileage"></use>
          </svg>
          <span>Пробег</span>
        </div>
        <div class="filter-range" data-filter="mileage">
          <div class="range-slider-wrap" data-format="kilometers" data-min="0" data-max="550000">
            <div class="range-slider"></div>
            <div class="range-slider-inputs">
              <input type="text" class="range-input js-range-from">
              <input type="text" class="range-input js-range-to">
            </div>
          </div>
        </div>
        <div class="filter-item-subtitle">
          <svg>
            <use xlink:href="#i-filter-owner"></use>
          </svg>
          <span>Максимальное число владельцев</span>
        </div>
        <div class="filter-quantity" data-filter="quantity-owners">
          <app-catalog-filter-owner-count></app-catalog-filter-owner-count>

          <div class="filter-checkbox" data-filter="dealers-auto">
            <label for="dealers-auto" class="checkbox-wrapper filter-checkbox-wrapper">
              <input type="checkbox" class="checkbox-input" id="dealers-auto">
              <span class="checkbox-box"><svg><use xlink:href="#i-check"></use></svg></span>
              <span class="checkbox-title">
                                            <span>Автопарк дилера</span>
                                            <span class="checkbox-tooltip-btn js-tooltip-btn" aria-describedby="tooltip-dealers-auto">
                                                <svg><use xlink:href="#i-filter-info"></use></svg>
                                            </span>
                                        </span>
            </label>
          </div>
          <div class="filter-checkbox" data-filter="hyundai-auto">
            <label for="hyundai-auto" class="checkbox-wrapper filter-checkbox-wrapper">
              <input type="checkbox" class="checkbox-input" id="hyundai-auto">
              <span class="checkbox-box"><svg><use xlink:href="#i-check"></use></svg></span>
              <span class="checkbox-title">
                                            <span>Автопарк Hyndai Mobility</span>
                                            <span class="checkbox-tooltip-btn js-tooltip-btn" aria-describedby="tooltip-hyundai-auto">
                                                <svg><use xlink:href="#i-filter-info"></use></svg>
                                            </span>
                                        </span>
            </label>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </app-catalog-filter-block>

  <app-catalog-filter-block title="Характеристики">
    <ng-container filterBlockBody>
      <app-catalog-filter-characteristics></app-catalog-filter-characteristics>
    </ng-container>
  </app-catalog-filter-block>

  <div class="filter-footer">
    <button class="filter-btn" (click)="resetFilter()">Сбросить фильтры</button>
  </div>
</div>
