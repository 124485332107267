import {environment} from '../../../environments/environment';

export class Logger {
  constructor(private readonly parentName: string) {

  }

  log(...args): void {
    if (environment.enableDebugLog) {
      console.log(`[${this.parentName}]`, ...args);
    }
  }

  error(...args): void {
    console.error(`[${this.parentName}]`, ...args);
  }
}
