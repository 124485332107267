<section class="bg-gray advantages-section">
  <div class="container-fluid container-lg advantages-slider-wrapper">
    <swiper
      slidesPerView="auto"
      [breakpoints]="breakpoints"
      (breakpoint)="handleResizeEvent($event)"
    >
      <ng-template swiperSlide>
        <div class="advantages-card">
          <div class="advantages-card-number">01</div>
          <div class="advantages-card-title">История <br class="d-md-none"> бесплатно</div>
          <div class="advantages-card-content">Мы собрали проверенную историю автомобиля с момента
            его выпуска и первой продажи в одном месте и делимся ей бесплатно.
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="advantages-card">
          <div class="advantages-card-number">02</div>
          <div class="advantages-card-title">Проверенные <br class="d-md-none"> автомобили</div>
          <div class="advantages-card-content">В нашем каталоге – только автомобили, которые прошли проверку
            на юридические ограничения. Все машины прошли предпродажное обслуживание и диагностику у
            официальных дилеров Hyundai.
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="advantages-card">
          <div class="advantages-card-number">03</div>
          <div class="advantages-card-title">Прозрачные <br class="d-md-none"> условия</div>
          <div class="advantages-card-content">Вы покупаете именно то, что выбираете. Цена зафиксирована.
            Забирайте автомобиль у официального дилера, после оплаты в приложении. Никаких неприятных
            торгов.
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="advantages-card">
          <div class="advantages-card-number">04</div>
          <div class="advantages-card-title">Безопасная <br class="d-md-none"> сделка</div>
          <div class="advantages-card-content">При покупке деньги на вашей карте будут заморожены, но мы
            получим
            их только после того, как вы заберете машину и убедитесь, что с ней всё в порядке.
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>
