import {Component, OnInit} from '@angular/core';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter',
  templateUrl: './catalog-filter.component.html',
  styleUrls: ['./catalog-filter.component.scss']
})
export class CatalogFilterComponent implements OnInit {

  constructor(
    private readonly carFilterService: CarFilterService,
  ) {
  }

  ngOnInit(): void {
  }

  resetFilter(): void {
    this.carFilterService.resetFilter();
  }
}
