import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {IApiCar} from '../../../api/interfaces/api-car.interface';
import {ModalService} from '../../../shared/services/modal.service';
import {Swiper, SwiperOptions, Thumbs, Navigation, Virtual, Lazy} from 'swiper';
import {Logger} from '../../../shared/helpers/logger';
import {SwiperComponent} from 'swiper/angular';
import {ReplaySubject} from 'rxjs';

Swiper.use([Virtual, Thumbs, Navigation, Lazy]);

@Component({
  selector: 'app-catalog-car-detail',
  templateUrl: './catalog-car-detail.component.html',
  styleUrls: ['./catalog-car-detail.component.scss']
})
export class CatalogCarDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('mainDetailSlider') mainSlider: SwiperComponent;
  @ViewChild('thumbsDetailSlider') thumbsSlider: SwiperComponent;

  car$ = new ReplaySubject<IApiCar>();

  id = 'car-modal';

  private logger = new Logger(CatalogCarDetailComponent.name);

  public mainSliderConfig: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: true,
    updateOnWindowResize: true,
    updateOnImagesReady: true,
    navigation: {
      prevEl: '.car-slide-nav.swiper-button-prev',
      nextEl: '.car-slide-nav.swiper-button-next',
      disabledClass: 'd-none',
    },
  };

  public thumbsSliderConfig: SwiperOptions = {
    slidesPerView: 5,
    updateOnImagesReady: true,
    updateOnWindowResize: true,
  };

  constructor(
    private readonly modalService: ModalService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.mainSlider.swiperRef.on('slideChange', this.handleChangeMainSlider.bind(this));
    this.thumbsSlider.swiperRef.on('click', this.handleClickThumbs.bind(this));
  }

  show(): void {
    this.modalService.open(this.id);

    setTimeout(() => {
      this.mainSlider.swiperRef.update();
      this.thumbsSlider.swiperRef.update();
      this.resetSliders();
    }, 100);
  }

  close(): void {
    this.modalService.close(this.id);
  }

  setCar(car: IApiCar): void {
    this.car$.next(car);
  }

  handleChangeMainSlider(swiper: Swiper): void {
    const currentIndex = swiper.activeIndex;
    this.thumbsSlider.swiperRef.slides.forEach((slide, index) => {
      if (index === currentIndex) {
        slide.classList.add('thumb-current-slide');
      } else {
        slide.classList.remove('thumb-current-slide');
      }
    });
    this.thumbsSlider.swiperRef.slideTo(currentIndex);
  }

  handleClickThumbs(swiper: Swiper, event: Event): void {
    const clickedIndex = swiper.clickedIndex;
    this.mainSlider.swiperRef.slideTo(clickedIndex);
  }

  resetSliders(): void {
    this.mainSlider.swiperRef.slideTo(0, 0, true);
    this.thumbsSlider.swiperRef.slideTo(0, 0, true);
    this.handleChangeMainSlider(this.mainSlider.swiperRef);
  }
}
