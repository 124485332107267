<section class="car-categories-section">
  <div class="container">
    <div class="section-title">Подборки</div>
    <div class="car-categories-slider">
      <div class="row">
        <div class="col col-12 col-md-4">
          <div class="car-categories-card">
            <div class="card-image">
              <img src="/assets/images/cars/car-catalog-1.png" alt="">
            </div>
            <div class="card-content">
              <div class="d-flex align-items-center">
                <div class="card-title">Кроссоверы <br> до 1 млн ₽</div>
                <div class="car-categories-badge ml-auto">12 авто</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-4">
          <div class="car-categories-card">
            <div class="card-image">
              <img src="/assets/images/cars/car-catalog-1.png" alt="">
            </div>
            <div class="card-content">
              <div class="d-flex align-items-center">
                <div class="card-title">Автомобили дилеров <br> после тест-драйвов</div>
                <div class="car-categories-badge ml-auto">27 авто</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-4">
          <div class="car-categories-card">
            <div class="card-image">
              <img src="/assets/images/cars/car-catalog-1.png" alt="">
            </div>
            <div class="card-content">
              <div class="d-flex align-items-center">
                <div class="card-title">Авто не старше <br> 1 года</div>
                <div class="car-categories-badge ml-auto">5 авто</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
