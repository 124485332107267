import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-filter-checkbox',
  templateUrl: './catalog-filter-checkbox.component.html',
  styleUrls: ['./catalog-filter-checkbox.component.scss']
})
export class CatalogFilterCheckboxComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  title: string;

  value = false;

  @Output()
  changeValue = new EventEmitter<boolean>();

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    this.carFilterService.filterReset$.subscribe(() => this.reset());
  }

  handleChange(): void {
    this.changeValue.emit(this.value);
  }

  private reset(): void {
    this.value = false;
    this.changeValue.emit(this.value);
  }
}
