<app-header-app-download-banner></app-header-app-download-banner>
<div class="page-catalog">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <app-catalog-filter class="d-none d-md-block"></app-catalog-filter>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-9">
        <div class="breadcrumbs">
          <div class="breadcrumbs-item">
            <a [routerLink]="['/']">
              <span>Главная</span>
            </a>
          </div>
          <div class="breadcrumbs-item">
            <svg width="2" height="11" viewBox="0 0 2 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 0.5V11" stroke="#B7B7B7"/>
            </svg>
            <span class="bread-current">Каталог</span>
          </div>
        </div>
        <h1 class="page-title-large">Каталог</h1>

        <div class="additional-filter">
          <div class="col-left">
            <app-city-select
              [options]="cities"
              (changeCity)="changeCity($event)"
            ></app-city-select>
          </div>
          <div class="col-right">
            <app-catalog-sort></app-catalog-sort>
          </div>
        </div>
        <div class="d-md-none">
          <button class="btn-rect btn-main-color btn-open-filter-mobile" appModalTrigger modalId="filter-modal">
            <svg>
              <use xlink:href="#i-filter"></use>
            </svg>
            <span>Фильтры</span>
          </button>
        </div>
        <app-catalog-list></app-catalog-list>
      </div>
    </div>
  </div>
</div>
<app-modal id="filter-modal" modalClass="modal-light modal-filter">
  <ng-container modalDialog>
    <div slot="modal-dialog" class="modal-dialog container-fluid">
      <div class="modal-content">
        <div class="modal-header-fixed">
          <div class="container d-flex align-items-center">
            <div class="modal-filter-title">
              <svg>
                <use xlink:href="#i-filter"></use>
              </svg>
              <span>Фильтры</span>
            </div>
            <div class="modal-close-wrapper ml-auto">
              <button type="button" class="close btn-img" data-dismiss="modal" aria-label="Close" appModalTrigger
                      modalId="filter-modal">
                <svg>
                  <use xlink:href="#i-close-modal"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <app-catalog-filter></app-catalog-filter>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-modal>
