<div [class]="{'catalog-filter-item': true, 'collapsed': isCollapsed}">
  <div
    class="filter-item-header"
    (click)="filterBlockCollapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
  >
    <span>{{title}}</span>
    <svg class="filter-item-btn-hide-body" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4L5 1L1 4" stroke="black" stroke-width="1.5"/>
    </svg>
  </div>
  <div #filterBlockCollapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="filter-item-body">
    <ng-content select="[filterBlockBody]"></ng-content>
  </div>
</div>
