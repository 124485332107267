import {Component, OnInit} from '@angular/core';
import {SortingDirection} from '../../../shared/consts/sorting-direction.const';
import {Logger} from '../../../shared/helpers/logger';
import {CarFilterService} from '../../services/car-filter.service';

interface SortingButton {
  code: string;
  title: string;
  svgCode: string;
  svg: string;
  active: boolean;
}

@Component({
  selector: 'app-catalog-sort',
  templateUrl: './catalog-sort.component.html',
  styleUrls: ['./catalog-sort.component.scss']
})
export class CatalogSortComponent implements OnInit {
  private readonly logger = new Logger(CatalogSortComponent.name);

  buttons: SortingButton[] = [
    {
      code: 'ukaPrice',
      title: 'Стоимость',
      svgCode: 'i-sorting-rub',
      svg: `<svg class="icon-sorting i-sorting-rub"><use xlink:href="#i-sorting-rub"></use></svg>`,
      active: false,
    },
    {
      code: 'year',
      title: 'Год выпуска',
      svgCode: 'i-sorting-calendar',
      svg: `<svg class="icon-sorting i-sorting-calendar"><use xlink:href="#i-sorting-calendar"></use></svg>`,
      active: false,
    },
    {
      code: 'mileage',
      title: 'Пробег',
      svgCode: 'i-sorting-mileage',
      svg: `<svg class="icon-sorting i-sorting-mileage"><use xlink:href="#i-sorting-mileage"></use></svg>`,
      active: false,
    },
  ];

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
  }

  handleChangeSorting(code: string, direction: SortingDirection): void {
    this.buttons.forEach(btn => {
      btn.active = btn.code === code;
    });

    this.carFilterService.setSorting(code, direction);
  }

  trackButton(index: number, button: SortingButton): string {
    return button.code;
  }
}
