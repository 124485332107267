<div class="quantity-filter-wrap">
  <div class="quantity-btn js-quantity-minus" (click)="sub()">
    <svg width="11" height="3" viewBox="0 0 11 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.5L1 1.5" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </div>
  <input type="text" class="quantity-filter-input filter-input js-quantity-filter" [(ngModel)]="value">
  <div class="quantity-btn js-quantity-plus" (click)="add()">
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 10L5.5 1" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M10 5.5L1 5.5" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </div>
</div>
