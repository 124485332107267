import {Component, OnInit} from '@angular/core';
import {ScriptLoaderService} from '../../../shared/services/script-loader.service';
import {environment} from '../../../../environments/environment';
import {Map} from 'yandex-maps';
import {IApiDealersCity, IApiDealersCityDealer} from '../../../api/interfaces/api-dealer-city.interface';
import {DealersService} from '../../services/dealers.service';

declare const ymaps: any;

@Component({
  selector: 'app-geography-map',
  templateUrl: './geography-map.component.html',
  styleUrls: ['./geography-map.component.scss']
})
export class GeographyMapComponent implements OnInit {
  private map: Map;
  private currentCity: IApiDealersCity;

  constructor(
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly dealersService: DealersService
  ) {
  }

  ngOnInit(): void {
    this.scriptLoaderService.processLoadScript({
      name: 'ya-map',
      src: environment.yandexMapUrl,
    }).then(name => this.handleScriptLoaded());

    this.dealersService.currentCity$.subscribe(city => {
      if (this.map) {
        this.showCity(city);
      } else {
        this.currentCity = city;
      }
    });

    this.dealersService.gotoDealer$.subscribe(dealer => this.goToDealer(dealer));
  }

  handleScriptLoaded(): void {
    ymaps.ready(() => this.handleYMapsReady());
  }

  handleYMapsReady(): void {
    this.map = new ymaps.Map('map', {
      center: [55.76, 37.64],
      zoom: 10,
      controls: [],
    });

    if (this.currentCity) {
      this.showCity(this.currentCity);
    }
  }

  showCity(city: IApiDealersCity): void {
    this.currentCity = city;
    const bounds = this.calculateBounds();
    this.map.setBounds(bounds, {duration: 300, checkZoomRange: true})
      .then(() => {
        if (this.map.getZoom() > 10) {
          this.map.setZoom(10);
        }
      });

    this.map.geoObjects.removeAll();
    this.currentCity.dealers.forEach((dealer, index) => {
      this.addDealerToMap(dealer, index);
    });
  }

  addDealerToMap(dealer: IApiDealersCityDealer, index: number): void {
    const coordinates = [dealer.latitude, dealer.longitude].map(v => parseFloat(v));
    const placeMark = new ymaps.Placemark(coordinates, {
      hintContent: dealer.dealerName,
      balloonContent: `<div class="balloon-dealer-name">${dealer.dealerName}</div><div>${dealer.dealerAddress}</div>`,
    }, {
      iconLayout: 'default#image',
      iconImageHref: '/assets/images/icons/i-placemark.svg',
      iconImageSize: [33, 41],
      iconImageOffset: [-16, 0],
      hideIconOnBalloonOpen: false,
    });

    placeMark.events
      .add('mouseenter', (e) => {
        const target = e.get('target');
        if (target.state.get('active') !== true) {
          e.get('target').options.set({
            iconImageHref: '/assets/images/icons/i-placemark-active.svg',
          });
        }
      })
      .add('mouseleave', (e) => {
        const target = e.get('target');
        if (target.state.get('active') !== true) {
          e.get('target').options.set({
            iconImageHref: '/assets/images/icons/i-placemark.svg',
          });
        }
      })
      .add('balloonopen', (e) => {
        e.get('target').options.set({
          iconImageHref: '/assets/images/icons/i-placemark-active.svg',
        });
      })
      .add('balloonclose', (e) => {
        e.get('target').options.set({
          iconImageHref: '/assets/images/icons/i-placemark.svg',
        });
      });

    this.map.geoObjects.add(placeMark, index);
  }

  calculateBounds(): number[][] {
    const coordinates: number[][] = this.currentCity.dealers.map(d => [d.latitude, d.longitude].map(v => parseFloat(v)));
    const lats = coordinates.map(([x]) => x).sort((a, b) => a - b);
    const lans = coordinates.map(([x, y]) => y).sort((a, b) => a - b);

    const minCoords = [lats[0], lans[0]];
    const maxCoords = [lats[lats.length - 1], lans[lans.length - 1]];

    return [minCoords, maxCoords];
  }

  goToDealer(dealer: IApiDealersCityDealer): void {
    const coordinates: number[] = [dealer.latitude, dealer.longitude].map(v => parseFloat(v));
    this.map.setCenter(coordinates, this.map.getZoom(), {duration: 300}).then();
  }
}
