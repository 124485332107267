<div class="svg">
  <svg xmlns="http://www.w3.org/2000/svg">
    <symbol id="i-logo" viewBox="0 0 72 27" stroke="none">
      <path d="M16.7612 0.546875V12.2964C16.7612 13.4444 16.5031 14.5924 15.9239 15.5774C15.261 16.7042 14.1235 17.8239 12.1976 17.8239C10.2717 17.8239 9.12727 16.6971 8.47134 15.5774C7.89216 14.5924 7.63397 13.4444 7.63397 12.2964V0.546875H0C0 0.546875 0 7.68302 0 16.0877C0 20.7577 3.76814 23.5781 7.1176 24.9813C10.3763 26.3419 14.0189 26.3419 17.2776 24.9813C20.6271 23.5852 24.3882 20.7648 24.3882 16.0877C24.3882 7.68302 24.3882 0.546875 24.3882 0.546875H16.7612Z"></path>
      <path d="M36.4461 13.2723L42.6286 6.83059C44.08 5.3495 44.0242 2.95425 42.51 1.53694C41.0376 0.162153 38.7418 0.225932 37.3462 1.68576L26.3628 13.2723L37.3532 24.8588C38.7488 26.3115 41.0446 26.3753 42.5169 25.0076C44.0382 23.5903 44.087 21.195 42.6356 19.7139L36.4461 13.2723Z"></path>
      <path d="M70.0727 4.59208C68.5026 2.29604 65.53 0 59.9127 0C54.2954 0 51.3227 2.29604 49.7527 4.59208C48.5664 6.3212 48.0151 8.41881 48.0151 10.5235V26.0005H55.056V20.7848H59.9057H64.7554V26.0005H71.7963V10.5235C71.8102 8.41173 71.259 6.3212 70.0727 4.59208ZM59.9127 12.9967H54.7769C54.7769 7.5826 59.9127 7.5826 59.9127 7.5826C59.9127 7.5826 65.0485 7.5826 65.0485 12.9967H59.9127Z"></path>
    </symbol>
    <symbol id="i-burger" viewBox="0 0 37 28" stroke="none">
      <rect width="36.3333" height="2.27083" rx="1.13542"></rect>
      <rect y="12.4902" width="36.3333" height="2.27083" rx="1.13542"></rect>
      <rect y="24.9785" width="36.3333" height="2.27083" rx="1.13542"></rect>
    </symbol>
    <symbol id="i-slider-arrow" viewBox="0 0 18 38" fill="none">
      <path d="M17 1L1.59055 18.3356C1.25376 18.7145 1.25376 19.2855 1.59055 19.6644L17 37" stroke="black"
            stroke-width="2"></path>
    </symbol>
    <symbol id="i-button-arrow" viewBox="0 0 7 12" fill="none">
      <path d="M1 1L4.89793 5.33103C5.24021 5.71134 5.24021 6.28866 4.89793 6.66896L1 11" fill="none"
            stroke-width="2"></path>
    </symbol>
    <symbol id="i-check" viewBox="0 0 17 13" fill="none">
      <path d="M1.54802 5.3788L6.93868 10.9594L15.3009 1.25289" stroke="#00AAD2" stroke-width="2"></path>
    </symbol>
    <symbol id="i-dropdown-arrow" viewBox="0 0 15 15" fill="none">
      <path d="M2.00037 7.65685L7.65723 13.3137L13.3141 7.65685" stroke="#002C5F" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"></path>
    </symbol>
    <symbol id="i-switcher-map" viewBox="0 0 19 19">
      <path d="M12.3333 1.85397L18 1V16.3714L12.3333 17.2253M12.3333 1.85397V17.2253M12.3333 1.85397L6.66667 1M12.3333 17.2253L6.66667 16.3714M6.66667 1V16.3714M6.66667 1L1 1.85397V17.2253L6.66667 16.3714"
            fill="none" stroke-width="1.5"></path>
    </symbol>
    <symbol id="i-switcher-list" viewBox="0 0 18 14">
      <path d="M1 1H17M1 7H17M1 13H17" fill="none" stroke-width="2" stroke-linecap="round"></path>
    </symbol>
    <symbol id="i-close-modal" viewBox="0 0 16 16">
      <rect y="14.5454" width="20.5702" height="2.05702" transform="rotate(-45 0 14.5454)"></rect>
      <rect x="1.45459" width="20.5702" height="2.05702" transform="rotate(45 1.45459 0)"></rect>
    </symbol>
    <symbol id="i-doc-arrow" viewBox="0 0 8 14" fill="none">
      <path d="M1 1l5.293 5.293a1 1 0 0 1 0 1.414L1 13" stroke="#00AAD2" stroke-width="2" stroke-linecap="round"></path>
    </symbol>

    <symbol id="i-filter" viewBox="0 0 16 15" fill="none">
      <path d="M1.00006 2.69189L3.86096 2.69189" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M1.00006 11.9966L3.86096 11.9966" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M4.79144 2.69189L15.0264 2.69189" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M4.79144 11.9966L15.0264 11.9966" stroke-width="1.2" stroke-linecap="round"/>
      <circle cx="5.6374" cy="2.77642" r="1.77632" transform="rotate(-90 5.6374 2.77642)" fill="#002C5F" stroke-width="1.2"/>
      <circle cx="5.6374" cy="12.0811" r="1.77632" transform="rotate(-90 5.6374 12.0811)" fill="#002C5F" stroke-width="1.2"/>
      <path d="M15.0264 7.34424L13.1655 7.34424" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M12.2349 7.34424L0.999962 7.34424" stroke-width="1.2" stroke-linecap="round"/>
      <circle cx="11.389" cy="7.25971" r="1.77632" transform="rotate(90 11.389 7.25971)" fill="#002C5F" stroke-width="1.2"/>
    </symbol>

    <symbol id="i-close-filter" viewBox="0 0 11 11" fill="none">
      <path d="M1 1L5.5 5.5M5.5 5.5L10 1M5.5 5.5L10 10M5.5 5.5L1 10" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>


    <symbol id="i-plus-filter" viewBox="0 0 11 11" fill="none">
      <path d="M5.5 10L5.5 1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 5.5L1 5.5" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>

    <symbol id="i-filter-calendar" viewBox="0 0 12 12" fill="none">
      <path d="M11 3L1 3" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 2L1 2" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 2L1 11L11 11L11 2" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.5 1C2.5 0.723858 2.27614 0.5 2 0.5C1.72386 0.5 1.5 0.723858 1.5 1H2.5ZM2.5 2.5V1H1.5V2.5H2.5Z" fill="#666666"/>
      <path d="M10.5 1C10.5 0.723858 10.2761 0.5 10 0.5C9.72386 0.5 9.5 0.723858 9.5 1H10.5ZM10.5 2.5V1H9.5V2.5H10.5Z" fill="#666666"/>
      <path d="M4.5 5.2C4.5 5.47614 4.72386 5.7 5 5.7C5.27614 5.7 5.5 5.47614 5.5 5.2H4.5ZM5.5 5C5.5 4.72386 5.27614 4.5 5 4.5C4.72386 4.5 4.5 4.72386 4.5 5H5.5ZM5.5 5.2V5H4.5V5.2H5.5Z" fill="#666666"/>
      <path d="M2.5 5.2C2.5 5.47614 2.72386 5.7 3 5.7C3.27614 5.7 3.5 5.47614 3.5 5.2H2.5ZM3.5 5C3.5 4.72386 3.27614 4.5 3 4.5C2.72386 4.5 2.5 4.72386 2.5 5H3.5ZM3.5 5.2V5H2.5V5.2H3.5Z" fill="#666666"/>
      <path d="M6.5 5.2C6.5 5.47614 6.72386 5.7 7 5.7C7.27614 5.7 7.5 5.47614 7.5 5.2H6.5ZM7.5 5C7.5 4.72386 7.27614 4.5 7 4.5C6.72386 4.5 6.5 4.72386 6.5 5H7.5ZM7.5 5.2V5H6.5V5.2H7.5Z" fill="#666666"/>
      <path d="M8.5 5.2C8.5 5.47614 8.72386 5.7 9 5.7C9.27614 5.7 9.5 5.47614 9.5 5.2H8.5ZM9.5 5C9.5 4.72386 9.27614 4.5 9 4.5C8.72386 4.5 8.5 4.72386 8.5 5H9.5ZM9.5 5.2V5H8.5V5.2H9.5Z" fill="#666666"/>
      <path d="M4.5 7.2C4.5 7.47614 4.72386 7.7 5 7.7C5.27614 7.7 5.5 7.47614 5.5 7.2H4.5ZM5.5 7C5.5 6.72386 5.27614 6.5 5 6.5C4.72386 6.5 4.5 6.72386 4.5 7H5.5ZM5.5 7.2V7H4.5V7.2H5.5Z" fill="#666666"/>
      <path d="M2.5 7.2C2.5 7.47614 2.72386 7.7 3 7.7C3.27614 7.7 3.5 7.47614 3.5 7.2H2.5ZM3.5 7C3.5 6.72386 3.27614 6.5 3 6.5C2.72386 6.5 2.5 6.72386 2.5 7H3.5ZM3.5 7.2V7H2.5V7.2H3.5Z" fill="#666666"/>
      <path d="M6.5 7.2C6.5 7.47614 6.72386 7.7 7 7.7C7.27614 7.7 7.5 7.47614 7.5 7.2H6.5ZM7.5 7C7.5 6.72386 7.27614 6.5 7 6.5C6.72386 6.5 6.5 6.72386 6.5 7H7.5ZM7.5 7.2V7H6.5V7.2H7.5Z" fill="#666666"/>
      <path d="M8.5 7.2C8.5 7.47614 8.72386 7.7 9 7.7C9.27614 7.7 9.5 7.47614 9.5 7.2H8.5ZM9.5 7C9.5 6.72386 9.27614 6.5 9 6.5C8.72386 6.5 8.5 6.72386 8.5 7H9.5ZM9.5 7.2V7H8.5V7.2H9.5Z" fill="#666666"/>
      <path d="M4.5 9.2C4.5 9.47614 4.72386 9.7 5 9.7C5.27614 9.7 5.5 9.47614 5.5 9.2H4.5ZM5.5 9C5.5 8.72386 5.27614 8.5 5 8.5C4.72386 8.5 4.5 8.72386 4.5 9H5.5ZM5.5 9.2V9H4.5V9.2H5.5Z" fill="#666666"/>
      <path d="M2.5 9.2C2.5 9.47614 2.72386 9.7 3 9.7C3.27614 9.7 3.5 9.47614 3.5 9.2H2.5ZM3.5 9C3.5 8.72386 3.27614 8.5 3 8.5C2.72386 8.5 2.5 8.72386 2.5 9H3.5ZM3.5 9.2V9H2.5V9.2H3.5Z" fill="#666666"/>
      <path d="M6.5 9.2C6.5 9.47614 6.72386 9.7 7 9.7C7.27614 9.7 7.5 9.47614 7.5 9.2H6.5ZM7.5 9C7.5 8.72386 7.27614 8.5 7 8.5C6.72386 8.5 6.5 8.72386 6.5 9H7.5ZM7.5 9.2V9H6.5V9.2H7.5Z" fill="#666666"/>
    </symbol>

    <symbol id="i-filter-mileage" viewBox="0 0 14 11" fill="none">
      <path d="M0.6 7C0.6 3.46538 3.46538 0.6 7 0.6C10.5346 0.6 13.4 3.46538 13.4 7C13.4 8.04489 13.15 9.02993 12.7069 9.9H1.29306C0.849961 9.02993 0.6 8.04489 0.6 7Z" stroke="#666666" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M6.5 7.5L10.5 4.5" stroke="#666666" stroke-width="1.2" stroke-linecap="round"/>
    </symbol>

    <symbol id="i-filter-owner" viewBox="0 0 12 12" fill="none">
      <circle cx="6" cy="3" r="2.4" stroke="#666666" stroke-width="1.2"/>
      <path d="M5 7.6H7C9.22653 7.6 11.0668 9.25379 11.3594 11.4H0.640567C0.933203 9.25379 2.77347 7.6 5 7.6Z" stroke="#666666" stroke-width="1.2"/>
    </symbol>
    <symbol id="i-filter-info" viewBox="0 0 16 16">
      <g opacity="0.7">
        <path d="M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1Z" fill="none" stroke-miterlimit="10"/>
        <path d="M8 11.5V7.41667" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.05833 3.79984C8.5738 3.79984 8.99167 4.2177 8.99167 4.73317C8.99167 5.24864 8.5738 5.6665 8.05833 5.6665C7.54287 5.6665 7.125 5.24864 7.125 4.73317C7.125 4.2177 7.54287 3.79984 8.05833 3.79984Z" stroke="none"/>
      </g>
    </symbol>
    <symbol id="i-filter-transmission" viewBox="0 0 10 13" fill="none">
      <path d="M9 6L0.999973 6" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 11.5L1 0.99996" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 11.5L5 0.99996" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 6L9 0.99996" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="i-filter-fuel-type" viewBox="0 0 9 12" fill="none">
      <path d="M8 7.2069C8 9.30177 6.433 11 4.5 11C2.567 11 1 9.30177 1 7.2069C1 5.11202 3.22727 2.37931 4.5 1C5.77273 2.37931 8 5.11202 8 7.2069Z" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="i-filter-drive" viewBox="0 0 9 12" fill="none">
      <path d="M4.63647 9.182L4.63647 2.81836" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.27271 2.36377L0.999973 2.36377" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.27271 9.63623L0.999973 9.63623" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.27271 3.72705L8.27271 0.999776" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.27271 11L8.27271 8.27273" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 3.72705L1 0.999776" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 11L1 8.27273" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="i-filter-color" viewBox="0 0 13 13" fill="none">
      <circle cx="6.5" cy="6.5" r="5.9" stroke="#666666" stroke-width="1.2"/>
      <path d="M5.9 12.3699C2.92333 12.0692 0.6 9.55593 0.6 6.5C0.6 3.44407 2.92333 0.930776 5.9 0.630133V12.3699Z" fill="#666666" stroke="#666666" stroke-width="1.2"/>
    </symbol>
    <symbol id="i-filter-additional" viewBox="0 0 13 13" fill="none">
      <path d="M6.5 9L6.5 4" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 6.5L4 6.5" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="6.5" cy="6.5" r="6" stroke="#666666"/>
    </symbol>
    <symbol id="i-map-marker" viewBox="0 0 13 18">
      <path d="M6.5 17.9993C6.5 17.9993 13 11.8395 13 7.16602C13 5.44211 12.3152 3.78881 11.0962 2.56982C9.87721 1.35084 8.22391 0.666016 6.5 0.666016C4.77609 0.666016 3.12279 1.35084 1.90381 2.56982C0.684819 3.78881 2.56882e-08 5.44211 0 7.16602C0 11.8395 6.5 17.9993 6.5 17.9993ZM6.5 10.416C5.63805 10.416 4.8114 10.0736 4.2019 9.46411C3.59241 8.85462 3.25 8.02797 3.25 7.16602C3.25 6.30406 3.59241 5.47741 4.2019 4.86792C4.8114 4.25843 5.63805 3.91602 6.5 3.91602C7.36195 3.91602 8.1886 4.25843 8.7981 4.86792C9.40759 5.47741 9.75 6.30406 9.75 7.16602C9.75 8.02797 9.40759 8.85462 8.7981 9.46411C8.1886 10.0736 7.36195 10.416 6.5 10.416Z"/>
    </symbol>


    <symbol id="i-arrow-down" viewBox="0 0 12 15">
      <path d="M5.43432 14.5657C5.74673 14.8781 6.25327 14.8781 6.56569 14.5657L11.6569 9.47452C11.9693 9.1621 11.9693 8.65557 11.6569 8.34315C11.3444 8.03073 10.8379 8.03073 10.5255 8.34315L6 12.8686L1.47452 8.34315C1.1621 8.03073 0.655566 8.03073 0.343146 8.34315C0.0307268 8.65557 0.0307268 9.1621 0.343146 9.47452L5.43432 14.5657ZM5.2 3.49691e-08L5.2 14L6.8 14L6.8 -3.49691e-08L5.2 3.49691e-08Z"/>
    </symbol>

    <symbol id="i-sorting-rub" viewBox="0 0 13 13">
      <path d="M4,7h.61v.61H4V8.7h.61V9.8h1.3V8.7H8.52V7.63H5.91V7H7.42A1.92,1.92,0,0,0,9.55,5c0-1.36-1-2-2.36-2H4.61V6H4ZM5.91,6V4.07H7c.73,0,1.22.25,1.22.94S7.72,6,7,6Z"/>
      <path d="M6.5,13A6.5,6.5,0,1,1,13,6.5,6.51,6.51,0,0,1,6.5,13Zm0-12A5.5,5.5,0,1,0,12,6.5,5.51,5.51,0,0,0,6.5,1Z"/>
    </symbol>


    <symbol id="i-sorting-calendar" viewBox="0 0 11 11">
      <path d="M3.63393 6.1875H2.65179C2.48973 6.1875 2.35714 6.07148 2.35714 5.92969V5.07031C2.35714 4.92852 2.48973 4.8125 2.65179 4.8125H3.63393C3.79598 4.8125 3.92857 4.92852 3.92857 5.07031V5.92969C3.92857 6.07148 3.79598 6.1875 3.63393 6.1875ZM6.28571 5.92969V5.07031C6.28571 4.92852 6.15312 4.8125 5.99107 4.8125H5.00893C4.84687 4.8125 4.71429 4.92852 4.71429 5.07031V5.92969C4.71429 6.07148 4.84687 6.1875 5.00893 6.1875H5.99107C6.15312 6.1875 6.28571 6.07148 6.28571 5.92969ZM8.64286 5.92969V5.07031C8.64286 4.92852 8.51027 4.8125 8.34821 4.8125H7.36607C7.20402 4.8125 7.07143 4.92852 7.07143 5.07031V5.92969C7.07143 6.07148 7.20402 6.1875 7.36607 6.1875H8.34821C8.51027 6.1875 8.64286 6.07148 8.64286 5.92969ZM6.28571 7.99219V7.13281C6.28571 6.99102 6.15312 6.875 5.99107 6.875H5.00893C4.84687 6.875 4.71429 6.99102 4.71429 7.13281V7.99219C4.71429 8.13398 4.84687 8.25 5.00893 8.25H5.99107C6.15312 8.25 6.28571 8.13398 6.28571 7.99219ZM3.92857 7.99219V7.13281C3.92857 6.99102 3.79598 6.875 3.63393 6.875H2.65179C2.48973 6.875 2.35714 6.99102 2.35714 7.13281V7.99219C2.35714 8.13398 2.48973 8.25 2.65179 8.25H3.63393C3.79598 8.25 3.92857 8.13398 3.92857 7.99219ZM8.64286 7.99219V7.13281C8.64286 6.99102 8.51027 6.875 8.34821 6.875H7.36607C7.20402 6.875 7.07143 6.99102 7.07143 7.13281V7.99219C7.07143 8.13398 7.20402 8.25 7.36607 8.25H8.34821C8.51027 8.25 8.64286 8.13398 8.64286 7.99219ZM11 2.40625V9.96875C11 10.5381 10.4721 11 9.82143 11H1.17857C0.527902 11 0 10.5381 0 9.96875V2.40625C0 1.83691 0.527902 1.375 1.17857 1.375H2.35714V0.257812C2.35714 0.116016 2.48973 0 2.65179 0H3.63393C3.79598 0 3.92857 0.116016 3.92857 0.257812V1.375H7.07143V0.257812C7.07143 0.116016 7.20402 0 7.36607 0H8.34821C8.51027 0 8.64286 0.116016 8.64286 0.257812V1.375H9.82143C10.4721 1.375 11 1.83691 11 2.40625ZM9.82143 9.83984V3.4375H1.17857V9.83984C1.17857 9.91074 1.24487 9.96875 1.32589 9.96875H9.67411C9.75513 9.96875 9.82143 9.91074 9.82143 9.83984Z"/>
    </symbol>
    <symbol id="i-sorting-mileage" viewBox="0 0 14 10.5">
      <path class="cls-1" d="M13.07,10.5H.93l-.17-.33A7,7,0,1,1,14,7a6.83,6.83,0,0,1-.76,3.17ZM1.67,9.3H12.33a5.8,5.8,0,1,0-10.66,0Z"/>
      <path class="cls-1" d="M6.5,8.1A.6.6,0,0,1,6,7.86.6.6,0,0,1,6.14,7l4-3a.6.6,0,0,1,.84.12.6.6,0,0,1-.12.84l-4,3A.6.6,0,0,1,6.5,8.1Z"/>
    </symbol>

    <symbol id="i-car-calendar" viewBox="0 0 13 12">
      <path d="M11.4355 3L1.43555 3" fill="none" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.4355 2L1.43555 2" fill="none" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.43555 2L1.43555 11L11.4355 11L11.4355 2" fill="none" stroke="#666666" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.93555 1C2.93555 0.723858 2.71169 0.5 2.43555 0.5C2.1594 0.5 1.93555 0.723858 1.93555 1H2.93555ZM2.93555 2.5V1H1.93555V2.5H2.93555Z" fill="#666666"/>
      <path d="M10.9355 1C10.9355 0.723858 10.7117 0.5 10.4355 0.5C10.1594 0.5 9.93555 0.723858 9.93555 1H10.9355ZM10.9355 2.5V1H9.93555V2.5H10.9355Z" fill="#666666"/>
      <path d="M4.93555 5.2C4.93555 5.47614 5.1594 5.7 5.43555 5.7C5.71169 5.7 5.93555 5.47614 5.93555 5.2H4.93555ZM5.93555 5C5.93555 4.72386 5.71169 4.5 5.43555 4.5C5.1594 4.5 4.93555 4.72386 4.93555 5H5.93555ZM5.93555 5.2V5H4.93555V5.2H5.93555Z" fill="#666666"/>
      <path d="M2.93555 5.2C2.93555 5.47614 3.1594 5.7 3.43555 5.7C3.71169 5.7 3.93555 5.47614 3.93555 5.2H2.93555ZM3.93555 5C3.93555 4.72386 3.71169 4.5 3.43555 4.5C3.1594 4.5 2.93555 4.72386 2.93555 5H3.93555ZM3.93555 5.2V5H2.93555V5.2H3.93555Z" fill="#666666"/>
      <path d="M6.93555 5.2C6.93555 5.47614 7.1594 5.7 7.43555 5.7C7.71169 5.7 7.93555 5.47614 7.93555 5.2H6.93555ZM7.93555 5C7.93555 4.72386 7.71169 4.5 7.43555 4.5C7.1594 4.5 6.93555 4.72386 6.93555 5H7.93555ZM7.93555 5.2V5H6.93555V5.2H7.93555Z" fill="#666666"/>
      <path d="M8.93555 5.2C8.93555 5.47614 9.1594 5.7 9.43555 5.7C9.71169 5.7 9.93555 5.47614 9.93555 5.2H8.93555ZM9.93555 5C9.93555 4.72386 9.71169 4.5 9.43555 4.5C9.1594 4.5 8.93555 4.72386 8.93555 5H9.93555ZM9.93555 5.2V5H8.93555V5.2H9.93555Z" fill="#666666"/>
      <path d="M4.93555 7.2C4.93555 7.47614 5.1594 7.7 5.43555 7.7C5.71169 7.7 5.93555 7.47614 5.93555 7.2H4.93555ZM5.93555 7C5.93555 6.72386 5.71169 6.5 5.43555 6.5C5.1594 6.5 4.93555 6.72386 4.93555 7H5.93555ZM5.93555 7.2V7H4.93555V7.2H5.93555Z" fill="#666666"/>
      <path d="M2.93555 7.2C2.93555 7.47614 3.1594 7.7 3.43555 7.7C3.71169 7.7 3.93555 7.47614 3.93555 7.2H2.93555ZM3.93555 7C3.93555 6.72386 3.71169 6.5 3.43555 6.5C3.1594 6.5 2.93555 6.72386 2.93555 7H3.93555ZM3.93555 7.2V7H2.93555V7.2H3.93555Z" fill="#666666"/>
      <path d="M6.93555 7.2C6.93555 7.47614 7.1594 7.7 7.43555 7.7C7.71169 7.7 7.93555 7.47614 7.93555 7.2H6.93555ZM7.93555 7C7.93555 6.72386 7.71169 6.5 7.43555 6.5C7.1594 6.5 6.93555 6.72386 6.93555 7H7.93555ZM7.93555 7.2V7H6.93555V7.2H7.93555Z" fill="#666666"/>
      <path d="M8.93555 7.2C8.93555 7.47614 9.1594 7.7 9.43555 7.7C9.71169 7.7 9.93555 7.47614 9.93555 7.2H8.93555ZM9.93555 7C9.93555 6.72386 9.71169 6.5 9.43555 6.5C9.1594 6.5 8.93555 6.72386 8.93555 7H9.93555ZM9.93555 7.2V7H8.93555V7.2H9.93555Z" fill="#666666"/>
      <path d="M4.93555 9.2C4.93555 9.47614 5.1594 9.7 5.43555 9.7C5.71169 9.7 5.93555 9.47614 5.93555 9.2H4.93555ZM5.93555 9C5.93555 8.72386 5.71169 8.5 5.43555 8.5C5.1594 8.5 4.93555 8.72386 4.93555 9H5.93555ZM5.93555 9.2V9H4.93555V9.2H5.93555Z" fill="#666666"/>
      <path d="M2.93555 9.2C2.93555 9.47614 3.1594 9.7 3.43555 9.7C3.71169 9.7 3.93555 9.47614 3.93555 9.2H2.93555ZM3.93555 9C3.93555 8.72386 3.71169 8.5 3.43555 8.5C3.1594 8.5 2.93555 8.72386 2.93555 9H3.93555ZM3.93555 9.2V9H2.93555V9.2H3.93555Z" fill="#666666"/>
      <path d="M6.93555 9.2C6.93555 9.47614 7.1594 9.7 7.43555 9.7C7.71169 9.7 7.93555 9.47614 7.93555 9.2H6.93555ZM7.93555 9C7.93555 8.72386 7.71169 8.5 7.43555 8.5C7.1594 8.5 6.93555 8.72386 6.93555 9H7.93555ZM7.93555 9.2V9H6.93555V9.2H7.93555Z" fill="#666666"/>
    </symbol>
    <symbol id="i-car-mileage" viewBox="0 0 14 11">
      <path d="M0.6 7C0.6 3.46538 3.46538 0.6 7 0.6C10.5346 0.6 13.4 3.46538 13.4 7C13.4 8.04489 13.15 9.02993 12.7069 9.9H1.29306C0.849961 9.02993 0.6 8.04489 0.6 7Z" fill="none" stroke="#666666" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M6.5 7.5L10.5 4.5" fill="none" stroke="#666666" stroke-width="1.2" stroke-linecap="round"/>
    </symbol>

    <symbol id="i-logo-hyundai" viewBox="0 0 164 21" fill="none">
      <path d="M153.269 5.338H146.543C145.422 5.40099 145.102 5.68828 145.102 7.05982V10.8232H153.27V5.338H153.269ZM156.856 2.30762V18.6688H153.268V13.9481H145.1V18.6678H141.513V5.84767C141.513 3.58372 142.441 2.30762 145.1 2.30762H156.856ZM137.959 5.84767V14.7136C137.959 17.5215 136.357 18.7022 134.371 18.7022H123.095V2.34007H134.371C137.446 2.30762 137.959 4.25374 137.959 5.84767ZM134.371 6.83648C134.371 5.94407 133.827 5.3065 132.866 5.3065H126.715V15.5449H132.866C134.275 15.4809 134.371 14.6515 134.371 13.9805V6.83648ZM50.257 2.30762H53.845V8.75015H61.8532V2.30762H65.4403V18.6688H61.8206V12.0048H53.8124V18.6688H50.2244V2.30762H50.257ZM71.5255 2.30762L76.0098 9.00499L80.4299 2.30762H84.7858L77.8024 12.7684V18.6688H74.2153V12.7684L67.2003 2.30762H71.5255ZM104.838 2.30762H116.369C118.002 2.30762 119.604 2.53096 119.54 5.84767V18.6688H115.953V6.99587C115.953 5.59379 115.793 5.27405 114.672 5.27405H108.394V18.6373H104.806V2.30762H104.838ZM164 18.6688H160.413V2.30762H164V18.6688ZM86.5803 18.7022H98.1114C100.386 18.7022 101.347 18.0303 101.282 14.746V2.30762H97.6953V14.013C97.6953 15.416 97.5352 15.5754 96.4146 15.5754H90.1367V2.30857H86.5487V18.7032H86.5803V18.7022Z" fill="white"/>
      <path d="M35.7466 3.26503C34.7219 2.72386 33.632 2.27717 32.543 1.86199C27.5784 0.204108 21.7808 -0.401968 16.2707 0.268056C11.1777 0.938079 6.2447 2.15023 2.37003 5.65688C0.51226 7.37966 -0.577678 9.9624 0.319574 12.4172C1.50441 15.4476 4.64383 17.4577 7.46212 18.604C15.7905 21.9541 27.0972 21.8262 35.1379 17.9989C37.893 16.6264 40.9672 14.2994 41.3516 11.0152C41.6727 7.44361 38.5333 4.79597 35.7466 3.26503ZM17.1689 4.69957C17.8735 3.36047 19.0909 1.76654 20.8202 1.73504C24.4399 1.70259 27.9312 2.24568 31.2297 3.16958C31.4224 3.26503 31.6783 3.32898 31.7742 3.51987C31.8701 3.74225 31.6774 3.8711 31.5499 4.0305C28.8275 6.13506 25.7197 7.31476 22.5496 8.36752C20.9477 8.7493 19.3143 9.45273 17.5198 9.06904C17.1038 8.97359 16.7194 8.68726 16.495 8.27207C16.0474 7.02842 16.6561 5.75328 17.1689 4.69957ZM6.11817 15.7034C5.70118 16.0852 5.28418 15.5746 4.90074 15.4152C2.97874 14.1715 1.24846 12.13 1.76131 9.73811C2.56174 6.61228 5.79704 4.92195 8.52043 3.77375C10.1213 3.16863 11.7873 2.69045 13.5493 2.40221C13.741 2.40221 13.9979 2.36975 14.0611 2.5616L13.9979 2.75344C10.5383 6.67719 8.26352 11.206 6.11817 15.7034ZM23.766 17.2019C22.9656 18.415 21.6849 19.4668 20.1147 19.3074C16.6235 19.2435 13.1649 18.7328 9.99284 17.7765C9.83275 17.7459 9.70525 17.6486 9.64102 17.5226C9.5768 17.3298 9.67267 17.1714 9.80112 17.0425C12.7162 14.7146 16.1432 13.6313 19.5712 12.4821C20.8519 12.1309 22.1968 11.7176 23.6385 11.9076C24.2156 11.9715 24.8243 12.3867 25.017 12.9279C25.3362 14.5543 24.4716 15.8943 23.766 17.2019ZM39.5907 11.3645C38.7902 14.6182 35.2348 16.3419 32.4155 17.4243C30.8779 17.9349 29.3403 18.4456 27.6426 18.604C27.5468 18.604 27.355 18.604 27.355 18.4456L27.3867 18.2556C29.7889 15.5746 31.5825 12.5766 33.1843 9.51572C33.9215 8.14417 34.5935 6.74113 35.2348 5.33905C35.3306 5.24265 35.4265 5.1787 35.5223 5.1472C36.0994 5.24265 36.5155 5.68838 36.9957 6.00812C38.6292 7.34721 40.1025 9.16544 39.5907 11.3645Z" fill="white"/>
    </symbol>

  </svg>
</div>
