<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-transmission"></use>
  </svg>
  <span>Коробка</span>
</div>
<div class="filter-group-buttons" data-filter="transmission">
  <app-catalog-filter-btn-toggle
    [options]="gearboxTypeOptions"
    (change)="updateGearbox($event)"
  ></app-catalog-filter-btn-toggle>
</div>
<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-fuel-type"></use>
  </svg>
  <span>Тип топлива</span>
</div>
<div class="filter-group-buttons" data-filter="fuel-type">
  <app-catalog-filter-btn-toggle
    [options]="engineTypeOptions"
    (change)="updateEngineType($event)"
  ></app-catalog-filter-btn-toggle>
</div>
<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-drive"></use>
  </svg>
  <span>Привод</span>
</div>
<div class="filter-group-buttons" data-filter="drive">
  <app-catalog-filter-btn-toggle
    [options]="driveTypeOptions"
    (change)="updateDriveType($event)"
  ></app-catalog-filter-btn-toggle>
</div>
<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-color"></use>
  </svg>
  <span>Цвет</span>
</div>
<div class="filter-group-buttons" data-filter="color">
  <app-catalog-filter-colors></app-catalog-filter-colors>
</div>
<div class="filter-item-subtitle">
  <svg>
    <use xlink:href="#i-filter-additional"></use>
  </svg>
  <span>Дополнительно</span>
</div>

<app-catalog-filter-checkbox
  [id]="'third-seat-row'"
  title="Есть 3-ий ряд сидений"
  (changeValue)="updateHasThirdSeat($event)"
></app-catalog-filter-checkbox>

<app-catalog-filter-checkbox
  [id]="'mobikey'"
  title="Есть Mobikey"
  (changeValue)="updateHasMobikey($event)"
>
  <ng-container afterTitle>
    <ng-template #mobikeyTipContent>
      <div id="tooltip-mobikey" class="tooltip-wrap checkbox-tooltip">
        <div class="tooltip-title">Mobikey</div>
        <div class="tooltip-desc">
          Это мобильное приложение для мониторинга и определения параметров автомобиля путем передачи данных через
          устройство Misos Lite
        </div>
        <div class="tooltip-close">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6.10352e-05" y="7.27246" width="10.2851" height="1.02851"
                  transform="rotate(-45 6.10352e-05 7.27246)" fill="#B7B7B7"/>
            <rect x="0.727264" width="10.2851" height="1.02851" transform="rotate(45 0.727264 0)" fill="#B7B7B7"/>
          </svg>
        </div>
      </div>
    </ng-template>
    <span class="checkbox-tooltip-btn" [ngbTooltip]="mobikeyTipContent">
            <svg><use xlink:href="#i-filter-info"></use></svg>
        </span>
  </ng-container>

</app-catalog-filter-checkbox>
