import {EventEmitter, Injectable} from '@angular/core';
import {ApiDealersService} from '../../api/services/api-dealers.service';
import {ReplaySubject} from 'rxjs';
import {IHomeGeographyCityDealer} from '../interfaces/home-geography-city-dealer.interface';
import {IApiDealersCityDealer} from '../../api/interfaces/api-dealer-city.interface';

@Injectable({
  providedIn: 'root'
})
export class DealersService {
  public cityDealers$ = new ReplaySubject<IHomeGeographyCityDealer[]>();
  public currentCity$ = new ReplaySubject<IHomeGeographyCityDealer>();
  public gotoDealer$ = new EventEmitter<IApiDealersCityDealer>();

  private cityDealers: IHomeGeographyCityDealer[];

  constructor(
    private readonly apiDealersService: ApiDealersService
  ) {
  }

  loadDealers(): void {
    const s = this.apiDealersService.getActiveDealers().subscribe(response => {
      this.cityDealers = response.map((item, index) => ({
        id: index,
        ...item,
      }));

      this.cityDealers$.next(this.cityDealers);
    });
  }

  setCurrentCity(cityId: number): void {
    const currentCity = this.cityDealers.find(i => i.id === cityId);
    this.currentCity$.next(currentCity);
  }
}
