import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IApiCarsResponse} from '../interfaces/api-cars.response.interface';
import {IApiCarsRequest} from '../interfaces/api-cars.request.interface';
import {Logger} from '../../shared/helpers/logger';
import {getApiUrl} from '../helpers/get-api-url.function';
import {makeQueryObject} from '../helpers/make-query-object.function';
import {IApiFilterAndCountResponse} from '../interfaces/api-filter-and-count.response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiCarsService {
  private readonly logger = new Logger(ApiCarsService.name);

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  getCars(filter: IApiCarsRequest = {}, skip?: number, take?: number): Observable<IApiCarsResponse> {
    const url = getApiUrl('/cars/filter');
    const params = makeQueryObject(Object.assign(filter, {skip, take}));

    this.logger.log('getCars filter:', filter);

    return this.httpClient.get<IApiCarsResponse>(url, {params}).pipe(
      tap(response => this.logger.log(response)),
    );
  }

  getFilterAndCount(): Observable<IApiFilterAndCountResponse> {
    const url = getApiUrl('/cars/filter-and-count');

    return this.httpClient.get<IApiFilterAndCountResponse>(url).pipe(
      tap(response => this.logger.log(response))
    );
  }
}
