<div class="sorting">
  <app-catalog-sort-button
    *ngFor="let btn of buttons; trackBy: trackButton"
    (changeSorting)="handleChangeSorting(btn.code, $event)"
    [active]="btn.active"
  >
    <ng-container buttonTitle>
      <svg [class]="['icon-sorting', btn.svgCode]">
        <use [attr.xlink:href]="'#' + btn.svgCode"></use>
      </svg>
      <span>{{btn.title}}</span>
    </ng-container>
  </app-catalog-sort-button>
</div>
