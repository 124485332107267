import {Component, OnInit} from '@angular/core';
import {CarFilterService} from '../../services/car-filter.service';
import {IRangeValue} from '../../../shared/interfaces/range-value.interface';

@Component({
  selector: 'app-catalog-filter-year',
  templateUrl: './catalog-filter-year.component.html',
  styleUrls: ['./catalog-filter-year.component.scss']
})
export class CatalogFilterYearComponent implements OnInit {
  maxYear = (new Date()).getFullYear();

  constructor(
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
  }

  updateFilter(value: IRangeValue): void {
    this.carFilterService.setYearRange(value);
  }
}
