import {Component, OnInit} from '@angular/core';
import {IDropdownOption} from '../../../shared/interfaces/dropdown-option.interface';
import {CityService} from '../../../shared/services/city.service';
import {DefaultCityId} from '../../../shared/consts/default-city-id.const';
import {CarFilterService} from '../../services/car-filter.service';

@Component({
  selector: 'app-catalog-page',
  templateUrl: './catalog-page.component.html',
  styleUrls: ['./catalog-page.component.scss']
})
export class CatalogPageComponent implements OnInit {
  cities: IDropdownOption<number>[] = [];

  constructor(
    private readonly cityService: CityService,
    private readonly carFilterService: CarFilterService
  ) {
  }

  ngOnInit(): void {
    this.cityService.loadCities();
    this.cityService.cities$.subscribe(cityResponse => {
      this.cities = cityResponse?.items?.map(item => ({
        id: item.id,
        name: item.name,
        value: item.id,
        selected: item.id === DefaultCityId,
      }));
    });
  }

  changeCity(cityId: number): void {
    this.carFilterService.setCity(cityId);
  }
}
