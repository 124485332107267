import {types} from 'util';
import {isSet} from '../../shared/helpers/is-set.function';

type MakeQueryObjectInput = Record<string, string | string[] | number | number[] | boolean>;
type MakeQueryObjectOutput = Record<string, string>;

export function makeQueryObject(data: MakeQueryObjectInput): MakeQueryObjectOutput {
  const result: MakeQueryObjectOutput = {};

  for (const field in data) {
    if (Object.prototype.hasOwnProperty.call(data, field) && isSet(data[field])) {
      result[field] = data[field].toString();
    }
  }

  return result;
}
