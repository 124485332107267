import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getApiUrl} from '../helpers/get-api-url.function';
import {Logger} from '../../shared/helpers/logger';
import {tap} from 'rxjs/operators';
import {IApiDealersCity} from '../interfaces/api-dealer-city.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiDealersService {
  private readonly logger = new Logger(ApiDealersService.name);

  constructor(
    private readonly http: HttpClient
  ) {
  }

  getActiveDealers(): Observable<IApiDealersCity[]> {
    const url = getApiUrl('/dealer/active');
    return this.http.get<IApiDealersCity[]>(url).pipe(
      tap(response => this.logger.log(response))
    );
  }
}
