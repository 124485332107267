import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ApiModelsService} from '../../api/services/api-models.service';
import {IApiModelsRequest} from '../../api/interfaces/api-models.request.interface';
import {IApiModel} from '../../api/interfaces/api-model.interface';
import {CityService} from './city.service';
import {Logger} from '../helpers/logger';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {
  private readonly logger = new Logger(ModelsService.name);
  public models$ = new ReplaySubject<IApiModel[]>();

  private models: IApiModel[] = [];
  private lastCityId: number;
  private homeSelectedModel: number;
  private defaultBrandIds = [1];

  constructor(
    private readonly apiModelsService: ApiModelsService,
    private readonly cityService: CityService
  ) {
  }

  public loadModels(): void {
    const cityId = this.cityService.getSelectedCityId();

    if (!this.models || !this.lastCityId || this.lastCityId !== cityId) {
      this.lastCityId = cityId;
      const filter: IApiModelsRequest = {
        cityIds: [cityId],
        brandIds: this.defaultBrandIds,
      };

      const sub = this.apiModelsService.filterModels(filter).subscribe(response => {
        this.models = response;
        this.models$.next(this.models);
        sub.unsubscribe();
      });
    }
  }

  public setHomeSelectedModel(modelId?: number): void {
    this.homeSelectedModel = modelId;
  }

  public getSelectedModel(): number {
    return this.homeSelectedModel;
  }
}
