import {NgModule} from '@angular/core';
import {LayoutComponent} from './components/layout/layout.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {SvgComponent} from './components/svg/svg.component';
import {RouterModule} from '@angular/router';
import { ModalDownloadAppComponent } from './components/modal-download-app/modal-download-app.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SvgComponent,
    ModalDownloadAppComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
  ],
  exports: [
    LayoutComponent,
  ]
})
export class CoreModule {
}
